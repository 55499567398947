import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  DASHBOARD_FETCH_DOORS_BEGIN,
  DASHBOARD_FETCH_DOORS_SUCCESS,
  DASHBOARD_FETCH_DOORS_FAILURE,
  DASHBOARD_FETCH_DOORS_DISMISS_ERROR,
} from './constants';
import DoorService from "../../../services/door.service";

export function fetchDoors(args = {}) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: DASHBOARD_FETCH_DOORS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      DoorService.getDoors().then(
        (res) => {
          dispatch({
            type: DASHBOARD_FETCH_DOORS_SUCCESS,
            data: res.data,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: DASHBOARD_FETCH_DOORS_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchDoorsError() {
  return {
    type: DASHBOARD_FETCH_DOORS_DISMISS_ERROR,
  };
}

export function useFetchDoors() {
  const dispatch = useDispatch();

  const { doors, fetchDoorsPending, fetchDoorsError, rooms, areas } = useSelector(
    state => ({
      doors: state.dashboard.doors,
      fetchDoorsPending: state.dashboard.fetchDoorsPending,
      fetchDoorsError: state.dashboard.fetchDoorsError,
      rooms: state.dashboard.doors && state.dashboard.doors.filter((door)=>(door.type==="room")).sort((a, b)=>(a.name<b.name?-1:1)),
      areas: state.dashboard.doors && state.dashboard.doors.filter((door)=>(door.type==="area")).sort((a, b)=>(a.name<b.name?-1:1))
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchDoors(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchDoorsError());
  }, [dispatch]);

  return {
    doors,
    fetchDoors: boundAction,
    fetchDoorsPending,
    fetchDoorsError,
    dismissFetchDoorsError: boundDismissError,
    rooms,
    areas
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_FETCH_DOORS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchDoorsPending: true,
        fetchDoorsError: null,
      };

    case DASHBOARD_FETCH_DOORS_SUCCESS:
      // The request is success
      return {
        ...state,
        doors: action.data,
        fetchDoorsPending: false,
        fetchDoorsError: null,
      };

    case DASHBOARD_FETCH_DOORS_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchDoorsPending: false,
        fetchDoorsError: action.data.error,
      };

    case DASHBOARD_FETCH_DOORS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchDoorsError: null,
      };

    default:
      return state;
  }
}
