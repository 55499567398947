import React, { useState, useEffect, useMemo } from 'react';
// import PropTypes from 'prop-types';
import { useFetchDoors } from './redux/hooks';
import { Select, Box,Text, TextInput } from 'grommet';
import { DoorStatus } from '../common';

export default function RoomSelect(props) {
  const { rooms } = useFetchDoors();
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState({});

  const [disabledLabel, setDisabledLabel ] = useState("");
  const { disabled } = props; 

  const memoizedRooms = useMemo(() => {
    return [{_id:0, name:"-"}].concat(rooms);
    }, []);

  useEffect(() => {
    var value = (memoizedRooms || []).find(x=>x && props.value===x.name);
    setValue(value || {});

    if (disabled && value)
    {
      setDisabledLabel(value.name);
    }

  }, [props.value]);

  useEffect(() => {
   // console.log("useEffect []", memoizedRooms);
    setOptions(memoizedRooms);
  }, [memoizedRooms]);

  function handleChange({ option })
  {
    setValue(option);
    if (!option.roomType)
      props.onChange({name:option.name});
    else
      props.onChange({name:option.name, roomType:option.roomType._id});
  }

  const renderOption = (option, state) => {
    return <Box pad="small" justify="between"
                background={state.active ? "active" : (option.name===value ? "brand" : undefined)} 
                direction="row">
      <Text color={option.lockId || option.name === "-" ? 'dark-1' : 'status-error'}>
        {option.name === "-" ? "Unassigned" : option.name}
      </Text>
      <DoorStatus status={option.status} />
      {
        !!option.roomType && 
        <Text truncate size="xsmall" color="dark-6" margin={{end:"5px"}}>{option.roomType.name}</Text>
      }
    </Box>
  };

  return (
    !disabled ? 
      <Select
        plain
        size="medium"
        placeholder="Select"
        valueLabel={value && <Box pad="small" justify="between" fill direction="row">
                <Text color={value.lockId || value.name === "-" ? 'dark-1' : 'status-error'}>
                  {value.name === "-" ? "Unassigned" : value.name}
                </Text>
                <DoorStatus status={value.status} />
                {
                  !!value.roomType && 
                  <Text truncate size="xsmall" color="dark-6" margin={{end:"5px"}}>{value.roomType.name}</Text>
                }
              </Box>}
        value={value}
        valueKey={{ key: "name", reduce: true }}
        dropHeight="medium"
        options={options}
        onChange={handleChange}
        onClose={() => setOptions(memoizedRooms)}
        onSearch={text => {
          // The line below escapes regular expression special characters:
          // [ \ ^ $ . | ? * + ( )
          const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");

          // Create the regular expression with modified value which
          // handles escaping special characters. Without escaping special
          // characters, errors will appear in the console
          const exp = new RegExp("^("+escapedText+")", "i");
          setOptions(memoizedRooms.filter(o => exp.test(o.name)));
        }}
      >{renderOption}</Select>
      :
      <TextInput plain value={disabledLabel || "" } />
  );
};

RoomSelect.propTypes = {};
RoomSelect.defaultProps = {};
