import axios from 'axios';
import { API_URL, authHeader } from './api';

const ENDPOINT_URL = API_URL + 'hotel/';

class HotelService {
  getHotel() {
    return axios.get(ENDPOINT_URL, { headers: authHeader() });
  }

  updateHotel(hotel) {
    return axios.patch(ENDPOINT_URL, hotel, { headers: authHeader() }, );
  }

  uploadCover(file) {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(ENDPOINT_URL + "uploadCover", 
                      formData, 
                      { headers: {...authHeader(), 'Content-Type': 'multipart/form-data' }});
  }

  uploadLogo(file) {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(ENDPOINT_URL + "uploadLogo", 
                      formData, 
                      { headers: {...authHeader(), 'Content-Type': 'multipart/form-data' }});
  }

}

export default new HotelService();