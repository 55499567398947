import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Box, Select, Button, Text } from 'grommet';
import { FormClose } from 'grommet-icons';

const defaultValues = [
    {id: "prearrival", name:"Pre-arrival"},
    {id: "checkedin", name:"Checked-in"}];

export default function ValidStatusesSelect(props) {
  
  const { currentValue } = props;
  const [options, setOptions] = useState(defaultValues);
  const [selected, setSelected] = useState([]);

  useEffect(()=>{
    if (currentValue)
    {
      const nextSelected = options.filter(a => !!currentValue.find(b=>a.id === b));
      setSelected(nextSelected);
    }
  }, [options]);


  const onRemoveStatus = status => {
    const nextSelected = selected.filter(selectedStatus => selectedStatus.id !== status.id);
    setSelected(nextSelected);
    props.onChange({target:{name:props.name, value:nextSelected}});
  };

  const renderStatus = status => (
    status && <Button
      key={`status_tag_${status.id}`}
      href="#"
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
        onRemoveStatus(status);
      }}
      onFocus={event => event.stopPropagation()}
    >
      <Box
        align="center"
        direction="row"
        gap="xsmall"
        pad={{ vertical: "xsmall", horizontal: "small" }}
        margin="xsmall"
        background="brand"
        round="large"
      >
        <Text size="small" color="white">
          {status.name}
        </Text>
        <Box background="white" round="full" margin={{ left: "xsmall" }}>
          <FormClose
            color="brand"
            size="small"
            style={{ width: "12px", height: "12px" }}
          />
        </Box>
      </Box>
    </Button>
  );

  function handleChange({option:nextSelected})
  {
    const alreadySelected = selected.find(sel => sel.id === nextSelected.id);
    if (alreadySelected)
    {
      onRemoveStatus(nextSelected);
    }
    else
    {
      setSelected([...selected, nextSelected]);
      props.onChange({target:{name:props.name, value:[...selected, nextSelected]}});
    }
  }
 
  const renderOption = (option, state) => {
    const isSelected = selected.find(sel => sel.id === option.id);
    return <Box pad="small"  gap="small"
                background={state.active ? "active" : (isSelected ? "brand" : undefined)} 
                direction="row">
      <Text alignSelf="center">{option.name}</Text>
    </Box>
  };

  return (
      <Select
        plain
        size="medium"
        placeholder="Select"
        value={selected}
        valueLabel={
            <Box wrap direction="row" >
              {selected && selected.length ? (
                selected.map(status => renderStatus(status))
              ) : (
                <Text color="dark-6">
                  Select
                </Text>
              )}
            </Box>
          }
        closeOnChange={false}
        multiple
        dropHeight="medium"
        options={options || []}
        onChange={handleChange}
        labelKey="name"
        valueKey={{ key: "id", reduce: true }}
        onClose={() => setOptions(defaultValues)}
        searchPlaceholder="Search"
        onSearch={text => {
          // The line below escapes regular expression special characters:
          // [ \ ^ $ . | ? * + ( )
          const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
          // Create the regular expression with modified value which
          // handles escaping special characters. Without escaping special
          // characters, errors will appear in the console
          const exp = new RegExp("^("+escapedText+")", "i");
          setOptions(defaultValues.filter(o => exp.test(o.name)));
        }}
      >{renderOption}</Select>
  );
};

ValidStatusesSelect.propTypes = {};
ValidStatusesSelect.defaultProps = {};
