import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Box, Button, Heading, Text, Accordion, AccordionPanel, Header, Image} from 'grommet';
import { Trash, Edit, Add, Menu } from 'grommet-icons';
import { presentToastSuccess, presentToastErrorContent } from '../common/Toast'
import { presentLoading, dismissLoading } from '../common/Loading'
import { useFetchHotel } from './redux/hooks';
import { ProductsMenu, AddCategory } from './';
import { CategoryService, PhotoService, ProductService } from "../../services";
import { presentAlertPrompt } from '../common/Alert';
import { roundPrice } from '../../common/utils';
import { AddProduct } from './';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';

export default function SettingsUpsell(props) {
  const { hotel } = useFetchHotel();
  const [ categories, setCategories ] = useState([]);
  const [ openEdit, setOpenEdit ] = useState(false);
  const [ categoryId, setCategoryId ] = useState(0);
  const [ openEditProduct, setOpenEditProduct ] = useState(false);
  const [ productId, setProductId ] = useState(0);

  const onRefresh = () => {
      presentLoading();
      CategoryService.getUpsells().then(
        (response) => {
          dismissLoading();
          setCategories(response.data);
        },
        (error) => {
          dismissLoading();
          presentToastErrorContent(error);
        }
      );
  }

  useEffect(() => {
    onRefresh();
  }, []);

    const handleEditModalClose = (refresh) =>
  {
    if (refresh)
      onRefresh();

    setOpenEdit(false);
  }

  const handleRemoveCategory = (categoryId) => {
    presentAlertPrompt({title:"Are you sure?", 
                message:"Do you really want to delete this category? This will remove all the products included in this category.", 
                onOK:()=>{removeCategory(categoryId)}, 
                buttonOKText:"Delete",
                buttonOKColor:"status-critical"
    });
  }

  const removeCategory = (categoryId) => {
    CategoryService.deleteCategory(categoryId).then(
      (response) => {
          onRefresh();
          presentToastSuccess("Category successfully removed.");
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  const handleEdit = (categoryId) => {
    setCategoryId(categoryId);
    setOpenEdit(true);
  }

  const handleAdd = () => {
    setCategoryId(0);
    setOpenEdit(true);
  }


  ////Product

  const handleEditProductModalClose = (refresh) =>
  {
    if (refresh)
      onRefresh();

    setOpenEditProduct(false);
  }

  const handleRemoveProduct = (productId) => {
    presentAlertPrompt({title:"Are you sure?",
                message:"Do you really want to delete this product?",
                onOK:()=>{removeProduct(productId)},
                buttonOKText:"Delete",
                buttonOKColor:"status-critical"
    });
  }

  const removeProduct = (productId) => {
    ProductService.deleteProduct(productId).then(
      (response) => {
          onRefresh();
          presentToastSuccess("Product successfully removed.");
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  const handleEditProduct = (productId) => {
    setProductId(productId);
    setOpenEditProduct(true);
  }

  const handleAddProduct = () => {
    setProductId(0);
    setOpenEditProduct(true);
  }

  const CategoryDragHandle = sortableHandle(({label}) => <Box direction="row" gap="small">
                                                  <Menu />
                                                  <Text>{label}</Text>
                                                  <i>(Category)</i>
                                                 </Box>);

    const ProductDragHandle = sortableHandle(({label}) => <Menu />);

  const SortableItem = sortableElement(({item}) => (
      item ? (item.isCategory ? 
        <AccordionPanel label={<CategoryDragHandle label={item.name} />} 
                      background={(!item.name || item.active) ? "light-1":"inactive"}
                      style={{height: 80, paddingLeft:10}}>
          <Box direction="row" gap="medium" justify="between" margin="small" 
              round background={(!item.name || item.active) ? "light-2":"inactive"} 
              pad="small" border >
            <Box direction="row" gap="medium" width="400px" align="start">
                <Box height="70px" width="70px">
                  <Image height="70px" width="70px"
                          fit="cover"
                          src={PhotoService.getURL(item.photo) || require('../../images/nophoto.jpg')}
                          className="sortable-item-image" onClick={() => handleEdit(item._id)} />
                </Box>
                <Box>
                  <Text weight="bold">{item.name} {item.isMenu && "(Menu)"}</Text>
                  {item.feeLabel && <Text>{item.feeLabel}: ${roundPrice(item.fee)}</Text>}
                  {item.taxPercent && <Text>Tax Rate: {roundPrice(item.taxPercent)}%</Text>}
                </Box>
            </Box>
            <Box direction="row" gap="medium" className="sortable-item-column">
              <Button icon={<Edit size="small" />} size="small" label="Edit Category" alignSelf="center"
                        primary color="status-warning"
                        onClick={() => handleEdit(item._id)}
                        />
              <Button icon={<Trash size="small" />} size="small" label="Remove Category" alignSelf="center"
                        primary color="status-critical"
                        onClick={() => handleRemoveCategory(item._id)}
                        />
            </Box>
          </Box>
          <ProductsMenu hotel={hotel} category={item}/>
      </AccordionPanel>
      :
      <Box border="bottom" direction="row" gap="medium" justify="start"  background={!item.active ? "inactive" : "transparent"}>
          <Box alignContent="center" justify="center" className="sortable-item-column">
            <ProductDragHandle />
          </Box>
          <Box direction="row" gap="medium" width="400px" className="sortable-item-column" align="start">
              <Box height="70px" width="70px">
                <Image height="70px" width="70px"
                        fit="cover"
                        src={PhotoService.getURL(item.photo) || require('../../images/nophoto.jpg')}
                        className="sortable-item-image" onClick={() => handleEditProduct(item._id)} />
              </Box>
              <Box gap="small" width="330px" className="sortable-item-column">
                <Text weight="bold">{item.name}</Text>
                <Text truncate color="dark-6" style={{fontStyle:'italic'}}>{item.description}</Text>
              </Box>
          </Box>
          <Box direction="row" gap="medium" width="70px" className="sortable-item-column" align="center">
                <Text weight="bold" >${roundPrice(item.price)}</Text>
          </Box>
          {
            item.isMenu && 
              <Box className="sortable-item-column" width="100px" justify="center"> 
                <Text weight="bold" >{item.subCategory}</Text>
              </Box>
          }
          <Box direction="row" gap="medium" className="sortable-item-column">
            <Button icon={<Edit size="small" />} size="small" label="Edit" alignSelf="center"
                      primary color="status-warning"
                      onClick={() => handleEditProduct(item._id)}
                      />
            <Button icon={<Trash size="small" />} size="small" label="Remove" alignSelf="center"
                      primary color="status-critical"
                      onClick={() => handleRemoveProduct(item._id)}
                      />
          </Box>
      </Box>) : <div></div>
  ));

  const SortableContainer = sortableContainer(({children}) => {
    return <Accordion className="accordion">{children}</Accordion>;
  });

  const onSortEnd = ({oldIndex, newIndex}) => {
    const newSortedList = arrayMove(categories, oldIndex, newIndex);
    const listIds = newSortedList.map(x=>{return {_id:x._id, isCategory:x.isCategory};});
    CategoryService.updateOrder(listIds).then(
      (response) => {
          setCategories(newSortedList);
          onRefresh();
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  };



  // const handleImport = () => {
  //   presentLoading();
  //   PmsService.importProducts().then(
  //     (response) => {
  //       onRefresh();
  //       dismissLoading();
  //     },
  //     (error) => {
  //       dismissLoading();
  //     }
  //   );
  // }

  return (
    <div style={{width:"100%"}}>
    <Heading level="1">Upsell Ads</Heading>
      <Header align="center" 
                direction="row" 
                justify="start" 
                gap="medium" 
                fill="horizontal">
          {/*<Button icon={<Download size="small"/>} label="Import from PMS" primary color="status-warning" onClick={handleImport}/>*/}
          <Button primary icon={<Add size="small" />} size="small" label="Add Category" onClick={handleAdd} />
          <Button primary icon={<Add size="small" />} size="small" label="Add Product" onClick={handleAddProduct} />
          { openEdit &&
            <AddCategory categoryId={categoryId} onClose={handleEditModalClose} />
          }
          { openEditProduct &&
            <AddProduct productId={productId} category={{}} onClose={handleEditProductModalClose} hotel={hotel}/>
          }
      </Header>
      <SortableContainer onSortEnd={onSortEnd} useDragHandle>
        {categories.map((item, index) => (
          <SortableItem key={`style-${item._id}`} index={index} item={item} />
        ))}
      </SortableContainer>
      
    </div>
  );
};

SettingsUpsell.propTypes = {};
SettingsUpsell.defaultProps = {};