import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Box, Form, FormField, Button, Select, Heading, TextInput, Layer} from 'grommet';
import { HotelService, PmsService, PaymentProcessorService } from '../../services';
import { presentToastSuccess, presentToastErrorContent } from '../common/Toast'
import { presentLoading, dismissLoading } from '../common/Loading'
import { useFetchHotel } from './redux/hooks';
import {CopyToClipboard} from 'react-copy-to-clipboard';


export default function SettingsPms(props) {
  const { hotel, fetchHotel } = useFetchHotel();
  const [formValues, setFormValues] = useState({});
  const [pmsList, setPmsList] = useState([]);
  const [helperOpen, setHelperOpen] = useState(false);
  const [helperList, setHelperList] = useState([]);
  const [helperId, setHelperId] = useState("");
  const [helperOptions, setHelperOptions] = useState([]);
  const [paymentProcessorList, setPaymentProcessorList] = useState([]);


  useEffect(() => {
    if (!hotel)
        setFormValues({});
    else
    {
      presentLoading();
      PmsService.getList().then(
        (response) => {
          dismissLoading();
          setPmsList(response.data);
          var nextPms = response.data.find(x=>x._id===hotel.pms);
          var nextFormValues = {pms: nextPms, 
                      pmsId: hotel.pms, 
                      pmsApiKeys: hotel.pmsApiKeys || []
                      };
          PaymentProcessorService.getList().then(
              (response) => {
                dismissLoading();
                setPaymentProcessorList([{keyLabels:[], _id:0, name: "None", slug: ""}, ...response.data]);
                var nextPaymentProcessor = response.data.find(x=>x._id===hotel.paymentProcessor);
                setFormValues({...nextFormValues, paymentProcessor: nextPaymentProcessor, 
                            paymentProcessorId: hotel.paymentProcessor, 
                            paymentProcessorApiKeys: hotel.paymentProcessorApiKeys || []
                            });
              },
              (error) => {
                dismissLoading();
                presentToastErrorContent(error);
              }
            );
        },
        (error) => {
          dismissLoading();
          presentToastErrorContent(error);
        }
      );
    }

  }, [hotel]);

  const handleCancel = () =>
  {
    if (!hotel)
      setFormValues({});
    else
    {
      var nextPms = pmsList.data.find(x=>x._id===hotel.pms);
      var nextPaymentProcessor = paymentProcessorList.find(x=>x._id===hotel.paymentProcessor);
      setFormValues({pms: nextPms, 
                  pmsId: hotel.pms, 
                  pmsApiKeys: hotel.pmsApiKeys || [],
                  paymentProcessor: nextPaymentProcessor, 
                  paymentProcessorId: hotel.paymentProcessor, 
                  paymentProcessorApiKeys: hotel.paymentProcessorApiKeys || []
                  });
    }
  }

  const handleOnSubmit = (e) =>
  {
    e.preventDefault();
    presentLoading();
    HotelService.updateHotel({
        pms:formValues.pms._id, 
        pmsApiKeys:formValues.pmsApiKeys,
        paymentProcessor:formValues.paymentProcessor && formValues.paymentProcessor._id, 
        paymentProcessorApiKeys:formValues.paymentProcessorApiKeys.slice(0, formValues.paymentProcessor.keyLabels.length)
        }).then(
      (response) => {
        dismissLoading();
        if (response.data.ok)
        {
          fetchHotel();
          presentToastSuccess("PMS Information Updated");
        }
      },
      (error) => {
        dismissLoading();
        presentToastErrorContent(error);
      }
    );
  }

  const handleOnHelper = (e) =>
  {
    e.preventDefault();
    presentLoading();
    PmsService.helper(formValues.pms.api, formValues.pmsApiKeys[0]).then(
      (response) => {
        dismissLoading();
        if (response.data)
        {
          setHelperList(response.data);
          setHelperOptions(response.data);
          setHelperOpen(true);
        }
      },
      (error) => {
        dismissLoading();
        presentToastErrorContent(error);
      }
    );
  }

  function handlePmsApiKeysChange(index, value)
  {
    var nextPmsApiKeys = [...formValues.pmsApiKeys];
    nextPmsApiKeys[index] = value;
    setFormValues({...formValues, pmsApiKeys:nextPmsApiKeys});
  }

  function handlePaymentProcessorApiKeysChange(index, value)
  {
    var nextPaymentProcessorApiKeys = [...formValues.paymentProcessorApiKeys];
    nextPaymentProcessorApiKeys[index] = value;
    setFormValues({...formValues, paymentProcessorApiKeys:nextPaymentProcessorApiKeys});
  }

  function closeHelper()
  {
    setHelperOpen(false);
  }

  return (
    <div>
    {helperOpen && (
        <Layer position="center" onClickOutside={closeHelper} onEsc={closeHelper}>
        <Box size="medium" gap="medium" pad="large">
          <Select
                  name="helper"
                  placeholder="Select"
                  value={helperId}
                  valueKey={{ key: "id", reduce: true }}
                  labelKey="name"
                  options={helperOptions}
                  onClose={() => setHelperOptions(helperList)}
                  onChange={({ value: nextValue }) => {
                      setHelperId(nextValue)
                    }
                  }
                  onSearch={text => {
                    const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
                    const exp = new RegExp(escapedText, "i");
                    setHelperOptions(helperList.filter(o => exp.test(o.name)));
                  }}
                />

          {helperId && 
            <Box direction="row" gap="medium">
            {helperId}
              <CopyToClipboard text={helperId} onCopy={closeHelper}>
                <Button primary label="Copy" />
              </CopyToClipboard>
            </Box>
          }
        </Box>
        </Layer>)
    }
      <Heading level="2">PMS Integration</Heading>
      <Box width="large">
        <Form>
          <Box direction="row" justify="between">
            <FormField label="PMS">
                <Select
                  name="pms"
                  placeholder="Select"
                  value={formValues.pmsId || "" }
                  valueKey={{ key: "_id", reduce: true }}
                  labelKey="name"
                  options={pmsList}
                  onChange={({ value: nextValue }) => {
                      var nextPms = pmsList.find(x=>x._id===nextValue);
                      setFormValues({...formValues, pms:nextPms, pmsId:nextValue})
                    }
                  }
                />
            </FormField>
            {
              formValues.pms && formValues.pms.api === "mews" &&
              <Button label="Helper" primary color="status-warning" onClick={handleOnHelper} style={{height:35, alignSelf:'center'}}/>
            }
          </Box>
          {
            formValues.pms && formValues.pms.keyLabels && formValues.pms.keyLabels.map((label, index) => {return (
              <FormField label={label} key={"keyLabel"+index}>
                <TextInput
                  value={formValues.pmsApiKeys[index] || ""}
                  onChange={({target: {value}})=>handlePmsApiKeysChange(index, value)}
                />
              </FormField>);}
            )
          }
        </Form>
      </Box>
      <Heading level="2" style={{marginTop:30}}>Payment Processor</Heading>
      <Box width="large">
        <Form>
          <Box direction="row" justify="between">
            <FormField label="Payment Processor">
                <Select
                  name="paymentProcessor"
                  placeholder="Select"
                  value={formValues.paymentProcessorId || "" }
                  valueKey={{ key: "_id", reduce: true }}
                  labelKey="name"
                  options={paymentProcessorList}
                  onChange={({ value: nextValue }) => {
                      var nextPaymentProcessor = paymentProcessorList.find(x=>x._id===nextValue);
                      setFormValues({...formValues, paymentProcessor:nextPaymentProcessor, paymentProcessorId:nextValue})
                    }
                  }
                />
            </FormField>
          </Box>
          {
            formValues.paymentProcessor && formValues.paymentProcessor.keyLabels && formValues.paymentProcessor.keyLabels.map((label, index) => {return (
              <FormField label={label} key={"keyLabelPaymentProcessor"+index}>
                <TextInput
                  value={formValues.paymentProcessorApiKeys[index] || ""}
                  onChange={({target: {value}})=>handlePaymentProcessorApiKeysChange(index, value)}
                />
              </FormField>);}
            )
          }
        </Form>
      </Box>
      <Box direction="row" justify="between" margin={{ top: "medium" }}>
        <Button label="Cancel" onClick={handleCancel}/>
        <Button type="submit" label="Update" primary onClick={handleOnSubmit} />
      </Box>

    </div>
  );
};

SettingsPms.propTypes = {};
SettingsPms.defaultProps = {};
