import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Markdown from 'markdown-to-jsx';
import { GuestService} from "../../services";
import { Image } from "grommet";
import { presentLoading, dismissLoading } from '../common/Loading';
import moment from 'moment-timezone';
import { useFetchHotel } from './redux/hooks';
import { presentToastErrorContent } from '../common/Toast';

export default function RegistrationCard() {

  var { guestId } = useParams();
  const [ booking, setBooking ] = useState();
  const [ content, setContent ] = useState("");
  const { hotel } = useFetchHotel();

 
  GuestService.getBooking(guestId).then(
    (response) => {
      const theBooking = response.data;
      if (theBooking._id)
      {
        setBooking(theBooking);
      }
    }
  );
  

  useEffect(() => {
    if (!hotel || !booking) return;
    let md = hotel.registrationText + "\n\n";
    md += "**Booking #:** " + booking.bookingId + "\n\n"; 
    md += "**Name of the guest:** " + booking.guest.firstname + " " + booking.guest.lastname + "\n\n"; 
    md += "**Room number:** " + (booking.roomNumber  || "-") + "\n\n"; 
    md += "**Check-in:** " + moment.tz(booking.checkinDate, hotel.timezone).format("LLLL") + "\n\n"; 
    md += "**Check-out:** " + moment.tz(booking.checkoutDate, hotel.timezone).format("LLLL") + "\n\n"; 
    md += "**Signature:**\n\n"; 
    setContent(md)
  }, [hotel, booking]);

  

  return (
    booking && booking.onlineCheckin ?
    <div>
      <Markdown>{content}</Markdown>
      <Image src={booking.onlineCheckin.signature} height="100"/>
    </div>
    :
    <div>Something went wrong</div>
  );
};

RegistrationCard.propTypes = {};
RegistrationCard.defaultProps = {};
