import React,  { useState } from 'react';
// import PropTypes from 'prop-types';
import AuthService from "../../services/auth.service";
import { Box, Text, Button, Collapsible } from 'grommet';
import { FormDown, FormNext } from 'grommet-icons';
import history from '../../common/history';

export default function NavBar(props) {

  const settingsPath = "/settings";

  const menu = [["Reservations", "/expected"], 
                ["Current Guests", "/guests"],
                ["Archived", "/archived"]];

  const menuSettings = [["Hotel Info", "/info"], 
                [ "Room Types","/roomtypes"],
                [ "Door Locks", "/doors"], 
                [ "Times","/times"],
                [ "Online Check-in","/checkin"],
                [ "Upsell Ads","/upsell"],
                [ "PMS Integration","/pms"],
                [ "Users","/users"],
                ];

  const [ openMenuSettings, setOpenMenuSettings ] = useState(window.location.pathname.includes(settingsPath));

  const handleLogout = (e) =>
  {
    e.preventDefault();
    AuthService.logout();
    history.push("/sign-in");
    window.location.reload();
  }


const SettingsButton = ({ open, ...rest }) => {
  const Icon = open ? FormDown : FormNext;
  return (
    <Button hoverIndicator 
            active={window.location.pathname.includes(settingsPath)}
            onClick={() => {
              setOpenMenuSettings(!open);
            }}>
        <Box pad={{ horizontal: "medium", vertical: "small" }} direction="row" justify="between">
          <Text>Settings</Text>
          <Icon color="white" />
        </Box>
    </Button>
  );
};


  return (
    <Box
      {...props}
      background="dark-3"
      width="200px"
      justify="between"
    >
      <Box>
        {menu.map(([name, path]) => (
          <Button key={name} href={path} hoverIndicator active={(path===window.location.pathname)}>
            <Box pad={{ horizontal: "medium", vertical: "small" }}>
              <Text>{name}</Text>
            </Box>
          </Button>
        ))}
        <SettingsButton key={settingsPath} open={openMenuSettings} />
        <Box background="background-contrast">
          <Collapsible open={ openMenuSettings } >
            {menuSettings.map(([name, path]) => (
              <Button key={name} href={settingsPath + path} 
                      hoverIndicator className="settings-button"
                      active={(settingsPath+path===window.location.pathname)}>
                <Box pad={{ horizontal: "medium", vertical: "small" }}>
                  <Text>{name}</Text>
                </Box>
              </Button>
            ))}
          </Collapsible>
        </Box>
      </Box>
      <Button key="logout" onClick={handleLogout} hoverIndicator className="menu-logout">
        <Box pad={{ horizontal: "medium", vertical: "small" }}>
          <Text>Logout</Text>
        </Box>
      </Button>
    </Box>
  );
};

NavBar.propTypes = {};
NavBar.defaultProps = {};
