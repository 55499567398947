import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { ResponsiveContext, Grid, Header, Box, Heading, Button, 
         FormField, TextInput, Text, CheckBox, Image,
         Tabs, Tab } from 'grommet';
import { GuestService, CheckinService, CommunicationService, ChargeService, NotificationService} from "../../services";
import { presentToastSuccess, presentToastErrorContent } from '../common/Toast';
import { BookingCalendarInput, BookingStatus, SecuredImage, HourInput } from '../common';
import moment from 'moment-timezone';
import { RoomSelect, RoomTypeSelect, DoorsSelect, VerifyCheckInModal, Conversation, ViewMenuRequestModal} from './';
import { formatPhoneNumber } from 'react-phone-number-input'
import { presentLoading, dismissLoading } from '../common/Loading';
import { useFetchHotel } from './redux/hooks';
import { presentAlertPrompt } from '../common/Alert';
import { FormNextLink } from 'grommet-icons';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const defaultValues = {
  _id:0,
  firstname:"",
  lastname:"",
  email:"",
  phone:"",
  checkinDate:moment().hour(15),
  checkoutDate:moment().add(1, 'days').hour(12),
  checkinTime:"15:00",
  checkoutTime:"12:00",
  roomNumber:"",
  roomType:0,
  accessDoors:[],
  currentDoors:[],
  invitationSent:false,
  status:{},
  onlineCheckin:{},
  magicLink:"",
  linkUsed:false,
  charges:[],
  messages:[]
}

const areas = {
        small: [
          [ 'info', 'info' ] ,
          [ 'status', 'status' ] ,
          [ 'upsell', 'upsell' ],
          [ 'keys', 'keys' ],
          [ 'magicLink', 'magicLink' ]
        ],
        medium: [
          [ 'info', 'status' ] ,
          [ 'upsell', 'keys' ],
          [ 'magicLink', 'magicLink' ]
        ]
      };

const rows = {
        small: ['auto','auto','auto','auto', 'auto'],
        medium: ['auto', 'auto', 'auto']
      };

export default function GuestDetails(props) {

  var contextSize = useContext(ResponsiveContext);
  if (contextSize !== "small") contextSize = "medium";

  var { guestId, tab } = useParams();
  const { hotel, fetchHotel } = useFetchHotel();
  const [ formValues, setFormValues ] = useState(defaultValues);

  const [tabIndex, setTabIndex] = useState((tab==="messages")? 1 : 0);
  const onActive = nextIndex => setTabIndex(nextIndex);

  const refreshDetails = (guestId) => {
      presentLoading();
      setFormValues({...formValues, roomNumber:""}); //little hack because of DoorSelect not refreshing
      GuestService.getBooking(guestId).then(
        (response) => {
          dismissLoading();
          const booking = response.data;
          if (booking._id)
          {
            setFormValues({...booking,
                        currentDoors:booking.accessDoors,
                        checkinDate:moment.tz(booking.checkinDate, hotel.timezone),
                        checkoutDate:moment.tz(booking.checkoutDate, hotel.timezone),
                        checkinTime:moment.tz(booking.checkinDate, hotel.timezone).format("HH:mm"),
                        checkoutTime:moment.tz(booking.checkoutDate, hotel.timezone).format("HH:mm")
                        });
          }
        },
        (error) => {
          dismissLoading();
          presentToastErrorContent(error);
        }
      );
    
    //setTimeout(() => PmsService.updateDoorStatus(), 1000);
  }

  useEffect(() => {
    if (tabIndex === 1)
    {
      NotificationService.removeMessage(guestId).then(
        (response) => {
          fetchHotel();
        }
      );
    }
  }, [tabIndex]);

  useEffect(() => {
    if (guestId !== 0)
    {
      refreshDetails(guestId);
    }
    else
    {
      //show GuestModal
      // setFormValues({...defaultValues,
      //     checkinDate:(!hotel.checkinTime)?defaultValues.checkinDate:moment(new Date()).format('YYYY/MM/DD '+ hotel.checkinTime +':00'),
      //     checkoutDate:(!hotel.checkoutTime)?defaultValues.checkoutDate:moment().add(1, 'days').format('YYYY/MM/DD '+ hotel.checkoutTime +':00')
      // });
      //onClose(true);
    }
  }, [guestId, hotel.timezone]);

  function handleInputChange({target:{name, value}}) {
    if (disabled()) return;
    var values = {...formValues};
    values[name] = value;
    setFormValues(values);
  }

  function handleRoomInputChange({name, roomType}) {
    var values = {...formValues, roomNumber:name};
    if (roomType) values.roomType = roomType;
    setFormValues(values);
  }

  // function handleRoomTypeInputChange({target:{name, value}}) {
  //   var values = {...formValues, roomType:value};
  //   if (formValues.roomNumber && formValues.roomNumber !== "-" && formValues.roomType === 0)
  //   values.roomNumber = "-";
  //   setFormValues(values);
  // }

  function handleCheckboxChange({target:{name, checked}}) {
    if (disabled()) return;
    var values = {...formValues};
    values[name] = checked;
    setFormValues(values);
  }

  function handleBookingDatesChange(checkinDate, checkoutDate)
  {
    var checkinDateString = moment(checkinDate).format("YYYY-MM-DD");
    var checkoutDateString = moment(checkoutDate).format("YYYY-MM-DD");
    checkinDate = moment.tz(checkinDateString + " " + formValues.checkinTime, hotel.timezone).utc();
    checkoutDate = moment.tz(checkoutDateString + " " + formValues.checkoutTime, hotel.timezone).utc();
    var values = {...formValues, checkinDate, checkoutDate};
    setFormValues(values);
  }

  function handleHourInputChange({name, value}) {
    var values = {...formValues};
    values[name] = value;
    var arr = value.split(':');
    var hour = parseInt(arr[0]);
    var minute = parseInt(arr[1]);
    if (name === "checkinTime")
    {
      values.checkinDate = formValues.checkinDate.hour(hour).minute(minute);
    }
    else if (name === "checkoutTime")
    {
      values.checkoutDate = formValues.checkoutDate.hour(hour).minute(minute);
    }

    setFormValues(values);
  }

  const handleUpdate = (action) =>
  {
    if (formValues._id !== 0)
    {
      var request = {...formValues};
      if (action === "info")
      {
        const {_id, firstname, lastname, email, phone, checkinDate, checkoutDate, roomType} = formValues;
        request =  {_id, firstname, lastname, email, phone, checkinDate, checkoutDate, roomType};
      }
      else if (action === "upsell")
      {
        const {_id, requestPhotoID, requestCardImprint, offerEarlyCheckin, offerUpgrade, offerLateCheckout} = formValues;
        request =  {_id, requestPhotoID, requestCardImprint, offerEarlyCheckin, offerUpgrade, offerLateCheckout};
      }
      GuestService.updateBooking(request).then(
        (response) => {
          if (response.data.ok)
          {
            if (typeof formValues.requestPhotoID !== 'boolean')
            {
              setFormValues({...formValues, checkinDate:moment.utc(formValues.checkinDate),
                                            checkoutDate:moment.utc(formValues.checkoutDate),
                                            requestPhotoID: true,
                                            requestCardImprint:true,
                                            offerEarlyCheckin:true,
                                            offerUpgrade:true,
                                            offerLateCheckout:true});
            }

            if (response.data.nModified || response.data.guestModified)
              presentToastSuccess("Guest details updated");
            refreshDetails(guestId);
          }
        },
        (error) => {
          presentToastErrorContent(error);
        }
      );
    }
  }

    const handleSendKeys = () =>
    {
      presentLoading(20000);
     // onClose();
      //console.log("formValues.accessDoors", formValues.accessDoors);
      GuestService.sendKeys(formValues._id, formValues.roomNumber, 
                            formValues.accessDoors,
                            moment.utc(formValues.checkinDate),
                            moment.utc(formValues.checkoutDate)
                            ).then(
          (response) => {
            dismissLoading();
            //onClose(true);
            presentToastSuccess("Door access updated");
            refreshDetails(guestId);
          },
          (error) => {
            dismissLoading();
            presentToastErrorContent(error);
          }
        );
    }

    const handleCheckout = () =>
    {
      presentAlertPrompt({title:"Are you sure?",
                    message:"This will update the check-out date and revoke the mobile key. The guest will be warned by text/email/notification.",
                    onOK:checkoutGuest,
                    buttonOKText:"Check out",
                    buttonOKColor:"status-critical"
      });
    };

    const checkoutGuest = () =>
    {
      presentLoading();
      GuestService.checkout(guestId).then(
          (response) => {
            dismissLoading();
            presentToastSuccess("The guest has successfully checked out");
            refreshDetails(guestId);
          },
          (error) => {
            dismissLoading();
            presentToastErrorContent(error);
          }
        );
    }

    const handleSubmitVerification = (approved) =>
    {
        presentLoading();
        CheckinService.approve(formValues._id, approved).then(
          (response) => {
            dismissLoading();
            presentToastSuccess("Status updated");
            refreshDetails(guestId);
          },
          (error) => {
            dismissLoading();
            presentToastErrorContent(error);
          }
        );
    }    


    const disabled = () =>
    {
      return  formValues.status.slug === "checkedout" ||
              formValues.status.slug === "noshow" ||
              formValues.status.slug === "cancelled";
    }

  const handleBack = () =>
  {
    var index = window.location.href.lastIndexOf("/");
    window.location.href = window.location.href.substring(0,index);
  };

  const renderCharge = (charge) =>
  {
    var label = "";
    var color = "";
    switch (charge.status)
    {
      case "confirmed":
        color = "status-ok";
        label = "Confirmed"
        break;
      case "cancelled":
        color = "status-error";
        label = "Cancelled"
        break;
      case "requested":
        color = "status-warning";
        label = "Requested"
        break;
      case "delivered":
        color = "neutral-3";
        label = "Delivered"
        break;
      default:
        label = "";
    }
    return (<Box justify="between" alignContent="center" direction="row" key={charge._id}>
              <Text>{charge.name}</Text>
              <Box style={{width: 'fit-content', margin: 'auto'}} pad={{horizontal:'small'}} align="center" round="small" border={{ color:color, size: 'small' }}>
                <Text size="12px" color={color}>{label}</Text>
              </Box>
              {
                charge.status === "requested" && (charge.reference === "menu" ?
                  <ViewMenuRequestModal onDecline={()=>handleDeclineCharge(charge)} 
                                        onApprove={()=>handleApproveCharge(charge)} 
                                        hotel={hotel} chargeId={charge._id} status={charge.status}/>
                : <Box direction="row" gap="small">
                  <Button style={{marginLeft:10, fontSize: 14}} label="Decline" size="small" onClick={()=>handleDeclineCharge(charge)} primary color="status-error" alignSelf="center"/>
                  <Button style={{fontSize: 14}} label="Approve" size="small" onClick={()=>handleApproveCharge(charge)} primary color="status-ok" alignSelf="center"/>
                </Box>
                )
              }
            </Box>);
  }

  const handleDeclineCharge = (charge) =>
  {
    presentLoading();
    ChargeService.cancel(charge._id).then(
      (response) => {
        dismissLoading();
        presentToastSuccess("Service cancelled. A email has been sent to the guest.");
        refreshDetails(guestId);
      },
      (error) => {
        dismissLoading();
        presentToastErrorContent(error);
      }
    );
  }

  const handleApproveCharge = (charge) =>
  {
    presentLoading();
    ChargeService.approve(charge._id).then(
      (response) => {
        dismissLoading();
        presentToastSuccess("Service approved. A email has been sent to the guest.");
        refreshDetails(guestId);
      },
      (error) => {
        dismissLoading();
        presentToastErrorContent(error);
      }
    );
  } 

  const handleSendMessage = () =>
  {
    setTabIndex(1);
  }

  const handleRefreshMessages = () => {
    CommunicationService.getMessages(formValues._id).then(
      (response) => {
        if (response.data)
        {
          setFormValues({...formValues, messages:response.data});
          NotificationService.removeMessage(guestId).then( () => { fetchHotel(); } );
        }
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  return (

    formValues._id ?
    <Box align="center" justify="start" pad="medium" gap="medium" flex="grow" margin={{bottom:"300px"}}>
      <Header align="center" direction="row" fill="horizontal" className="details-header">
        <Button label="Back" primary onClick={handleBack} className="back-button"/>
        <Heading level="2" className="heading">Reservation # : {formValues.bookingId}</Heading>
      </Header>
      <Tabs activeIndex={tabIndex} onActive={onActive} className="details-tabs">
        <Tab title="Information">
          <Grid className="home-page"
              areas={areas[contextSize]}
              columns={[["auto", "600px"], ["auto", "400px"]]}
              rows={rows[contextSize]}
              gap='medium'
            >
            <Box gridArea="info" pad="medium" gap="small" background={disabled()?"disabled-background":"light-1"} round >
              <Box direction="row" gap="small">
                <FormField label="First Name" alignSelf="end" >
                    <TextInput name="firstname" value={formValues.firstname} onChange={handleInputChange}/>
                </FormField>
                <FormField label="Last Name" alignSelf="end">
                    <TextInput name="lastname" value={formValues.lastname} onChange={handleInputChange} />
                </FormField>
              </Box>
              <BookingCalendarInput checkinDate={moment.tz(formValues.checkinDate, hotel.timezone)}
                                    checkoutDate={moment.tz(formValues.checkoutDate, hotel.timezone)}
                                    onChange={handleBookingDatesChange}
                                    disabled={disabled()}/>

              <Box direction="row" gap="small">
                <FormField label="Check-in Time">
                  <HourInput name="checkinTime" value={formValues.checkinTime} onChange={handleHourInputChange} disabled={disabled()} timezone={hotel.timezone}/>
                </FormField>
                <FormField label="Check-out Time">
                  <HourInput name="checkoutTime" value={formValues.checkoutTime } onChange={handleHourInputChange} disabled={disabled()} timezone={hotel.timezone}/>
                </FormField>
              </Box>
              <FormField label="Room Type" >
                <RoomTypeSelect name="roomType" value={formValues.roomType} onChange={handleInputChange} disabled={disabled()}/>
              </FormField>
              <FormField label="Email" >
                <TextInput name="email" value={formValues.email} onChange={handleInputChange} placeholder="example@my.com"/>
              </FormField>
              <Button label="Save" onClick={()=>handleUpdate("info")} primary alignSelf="center" disabled={disabled()}/>
            </Box>
            <Box gridArea="status" pad="medium" gap="medium" background={disabled()?"disabled-background":"light-1"} round  align="center">
            {
              formValues.onlineCheckin && formValues.onlineCheckin.photoSelfie ?
              <SecuredImage height="250px" filename={formValues.onlineCheckin.photoSelfie} />
              :
                <Image height="150px" width="150px"
                          fit="cover"
                          src={require('../../images/placeholder-guest.png')}
                          className="details-guest-photo" />
            }
              <Box><BookingStatus status={formValues.status} /></Box>
              <Text alignSelf="center" color="dark-1" weight="bold">Phone Number: {formatPhoneNumber(formValues.phone)}</Text>
              <Button label="Send Message" onClick={handleSendMessage} primary alignSelf="center"/>
              {
                !disabled() &&
                <Button label="Check out" onClick={handleCheckout} primary color="status-error" alignSelf="center"/>
              }
            </Box>
            <Box gridArea="upsell" pad="medium" gap="small" background={disabled()?"disabled-background":"light-1"} round >
              <Heading level={4} margin="none"> Online Check-in </Heading>
              {
                !formValues.onlineCheckin ?
                <Box gap="small">
                  <CheckBox name="requestPhotoID"
                            checked={formValues.requestPhotoID}
                            onChange={handleCheckboxChange}
                            label="Request Photo ID + Selfie"/>
                  <CheckBox name="requestCardImprint"
                            checked={formValues.requestCardImprint}
                            onChange={handleCheckboxChange}
                            label="Request Card Imprint"/>
                </Box>
                :
                <VerifyCheckInModal onSubmit={handleSubmitVerification} 
                                    status={formValues.status.slug}
                                    checkinData={formValues.onlineCheckin}
                                    bookingId={formValues._id}
                                    />
              }
              <Heading level={4} margin="none"> Upsell </Heading>

              {formValues.charges.map(charge => renderCharge(charge)) }

              {!formValues.charges.find(charge => charge.reference==="earlyCheckin") && 
                <CheckBox name="offerEarlyCheckin"
                          checked={formValues.offerEarlyCheckin}
                          onChange={handleCheckboxChange}
                          label="Offer Early Check-in"/>
              }
              {!formValues.charges.find(charge => charge.reference==="upgrade") && 
                <CheckBox name="offerUpgrade"
                          checked={formValues.offerUpgrade}
                          onChange={handleCheckboxChange}
                          label="Offer Upgrade"/>
              }
              {!formValues.charges.find(charge => charge.reference==="lateCheckout") && 
                <CheckBox name="offerLateCheckout"
                          checked={formValues.offerLateCheckout}
                          onChange={handleCheckboxChange}
                          label="Offer Late Check-out"/>
              }
            <Button label={formValues.invitationSent ?"Update":"Send invitation"}
                    onClick={()=>handleUpdate("upsell")} primary color="brand" alignSelf="center"
                    disabled={disabled()}/>
            </Box>
            <Box gridArea="keys" pad="medium" gap="medium" background={disabled()?"disabled-background":"#f5efff"} round >
              <Heading level={4} margin="none"> Mobile Keys </Heading>
              <FormField label="Assign a room">
                <RoomSelect value={formValues.roomNumber} onChange={handleRoomInputChange} disabled={disabled()}/>
              </FormField>
              <DoorsSelect name="accessDoors" value={formValues.accessDoors || []} currentValue={formValues.currentDoors || []}
                          onChange={handleInputChange} suggestedRoom={formValues.roomNumber} />

              <Box direction="row" justify="between" align="center">
                <Box style={{width: 'fit-content', margin: 'auto'}} pad={{horizontal:'small'}} align="center" round="small" border={{ color:"status-ok", size: 'small' }}>
                  <Text size="xsmall" color="status-ok">{moment.tz(formValues.checkinDate, hotel.timezone).format('ll')}</Text>
                  <Text size="xsmall" color="status-ok">{moment.tz(formValues.checkinDate, hotel.timezone).format('LT')}</Text>
                </Box>
                <FormNextLink />
                <Box style={{width: 'fit-content', margin: 'auto'}} pad={{horizontal:'small'}} align="center" round="small" border={{ color:"status-error", size: 'small' }}>
                  <Text size="xsmall" color="status-error">{moment.tz(formValues.checkoutDate, hotel.timezone).format('ll')}</Text>
                  <Text size="xsmall" color="status-error">{moment.tz(formValues.checkoutDate, hotel.timezone).format('LT')}</Text>
                </Box>
              </Box>
              <Button label={(formValues.currentDoors && formValues.currentDoors.length===0)?"Send Keys":"Update keys"}
                        onClick={()=>handleSendKeys()} primary color="brand" alignSelf="center"
                        disabled={formValues.accessDoors && formValues.currentDoors && (formValues.accessDoors.length + formValues.currentDoors.length === 0)}/>
            </Box>
            <Box gridArea="magicLink" pad="medium" gap="small" background={disabled()?"disabled-background":"light-1"} round >
              <Heading level={4} margin="none"> Mobile app magic link </Heading>
              {
                formValues.magicLink && (!formValues.linkUsed ? 
                <Box direction="row" gap="medium">
                {formValues.magicLink}
                  <CopyToClipboard text={formValues.magicLink} >
                    <Button primary label="Copy" />
                  </CopyToClipboard>
                </Box>
                : <Text>Link used</Text>)
              }
            </Box>
          </Grid>
        </Tab>
        
        <Tab title="Conversation" className={hotel.notifications.filter(x=>x.booking===guestId && x.type==='message').length>0 && "has-new-messages"}>
          <Box direction="row" justify="between">
            <Text weight="bold" size="medium" >{formValues.firstname} {formValues.lastname}</Text>
            <Button label="Refresh"
                        onClick={()=>handleRefreshMessages()} primary color="brand" alignSelf="end" />
          </Box>
          <Conversation bookingId={guestId} messages={formValues.messages} hotel={hotel}/>
        </Tab>
      </Tabs>
    </Box>
    : <div></div>
  );
};

GuestDetails.propTypes = {};
GuestDetails.defaultProps = {};
