import axios from 'axios';
import { API_URL, authHeader } from './api';

const ENDPOINT_URL = API_URL + 'hotel/charge/';

class ChargeService {

  get(chargeId) {
    return axios.get(ENDPOINT_URL + chargeId, { headers: authHeader() }, );
  }

  getList(bookingId) {
    return axios.get(ENDPOINT_URL + "list/" + bookingId, { headers: authHeader() }, );
  }

  approve(chargeId) {
    return axios.patch(ENDPOINT_URL + chargeId, {status:"confirmed"}, { headers: authHeader() }, );
  }

  cancel(chargeId) {
    return axios.patch(ENDPOINT_URL + chargeId, {status:"cancelled"}, { headers: authHeader() }, );
  }


}

export default new ChargeService();