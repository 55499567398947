import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Box, Form, FormField, Button, Select, Heading} from 'grommet';
import { HotelService, GuestService } from "../../services";
import { presentToastSuccess, presentToastErrorContent } from '../common/Toast'
import { HourInput } from '../common';
import { useFetchHotel } from './redux/hooks';
import uniqueTimeZones from '../../common/timezones';
import { presentAlertPrompt } from '../common/Alert';


const defaultValues = {
  checkinTime:"15:00",
  checkoutTime:"12:00",
  earlyCheckinTime:"12:00",
  lateCheckoutTime:"15:00",
  timezone:"America/Los_Angeles",
}

export default function SettingsHotelTimes(props) {
  const { hotel, fetchHotel } = useFetchHotel();
  const [formValues, setFormValues] = useState(defaultValues);
  const [timezoneOptions, setTimezoneOptions] = useState(uniqueTimeZones);

  useEffect(() => {
    if (!hotel)
      setFormValues(defaultValues);
    else
      setFormValues({checkinTime: hotel.checkinTime || defaultValues.checkinTime,
                     checkoutTime: hotel.checkoutTime || defaultValues.checkoutTime,
                     earlyCheckinTime: hotel.earlyCheckinTime || defaultValues.earlyCheckinTime,
                     lateCheckoutTime: hotel.lateCheckoutTime || defaultValues.lateCheckoutTime,
                     timezone: hotel.timezone || defaultValues.timezone
                    });
  }, [hotel]);

  const handleCancel = () =>
  {
    if (!hotel)
      setFormValues(defaultValues);
    else
      setFormValues({checkinTime: hotel.checkinTime || defaultValues.checkinTime,
                     checkoutTime: hotel.checkoutTime || defaultValues.checkoutTime,
                     earlyCheckinTime: hotel.earlyCheckinTime || defaultValues.earlyCheckinTime,
                     lateCheckoutTime: hotel.lateCheckoutTime || defaultValues.lateCheckoutTime,
                     timezone: hotel.timezone || defaultValues.timezone,
                    });
  }

  const handleOnSubmit = (e) =>
  {
    e.preventDefault();
    HotelService.updateHotel(formValues).then(
      (response) => {
        if (response.data.ok)
        {
          if (hotel.checkinTime !== formValues.checkinTime ||  hotel.checkoutTime !== formValues.checkoutTime)
          {
              presentAlertPrompt({title:"Check-in/out updated", 
                message:"Would you like to update future reservations too?", 
                onOK:updateFutureBookings,
                onCancel:fetchHotel,
                buttonOKText:"Update",
                buttonOKColor:"status-critical"
              });
          }
          presentToastSuccess("Time Information Updated");
        }
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  const updateFutureBookings = () =>
  {
    GuestService.updateFutureBookings().then(
      (response) => {
        if (response.data.success)
        {
          fetchHotel();
          presentToastSuccess("All future reservations have been updated");
        }
      },
      (error) => {
        fetchHotel();
        presentToastErrorContent(error);
      }
    );  
  }

  function handleInputChange({name, value}) {
    var values = {...formValues};
    values[name] = value;
    console.log(values);
    setFormValues(values);
  }

  return (
    <div>
      <Heading level="2">Times settings</Heading>
      <Box width="large">
        <Form >
          <FormField label="Timezone Identifier">
              <Select
                  name="timezone"
                  placeholder="Select"
                  value={formValues.timezone}
                  options={timezoneOptions}
                  onClose={() => setTimezoneOptions(uniqueTimeZones)}
                  onChange={({ value: nextValue }) => {
                      setFormValues({...formValues, timezone:nextValue})
                    }
                  }
                  onSearch={text => {
                    const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
                    const exp = new RegExp(escapedText, "i");
                    setTimezoneOptions(uniqueTimeZones.filter(o => exp.test(o)));
                  }}
                />
          </FormField>
          <FormField label="Check-in Time">
              <HourInput
                name="checkinTime"
                value={formValues.checkinTime}
                onChange={handleInputChange}
                timezone={formValues.timezone}
              />
          </FormField>
          <FormField label="Check-out Time">
              <HourInput
                name="checkoutTime"
                value={formValues.checkoutTime }
                onChange={handleInputChange}
                timezone={formValues.timezone}
              />
          </FormField>
          <FormField label="Early Check-in Time">
              <HourInput
                name="earlyCheckinTime"
                value={formValues.earlyCheckinTime }
                onChange={handleInputChange}
                timezone={formValues.timezone}
              />
          </FormField>
          <FormField label="Late Check-out Time">
              <HourInput
                name="lateCheckoutTime"
                value={formValues.lateCheckoutTime }
                onChange={handleInputChange}
                timezone={formValues.timezone}
              />
          </FormField>
          <Box direction="row" justify="between" margin={{ top: "medium" }}>
            <Button label="Cancel" onClick={handleCancel}/>
            <Button type="submit" label="Update" primary onClick={handleOnSubmit} />
          </Box>
        </Form>
      </Box>
    </div>
  );
};

SettingsHotelTimes.propTypes = {};
SettingsHotelTimes.defaultProps = {};
