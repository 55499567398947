import axios from 'axios';
import { API_URL, authHeader } from './api';

const ENDPOINT_URL = API_URL + 'payment-processor/';

class PaymentProcessorService {
  getList() {
    return axios.get(ENDPOINT_URL + 'list', { headers: authHeader() });
  }
  
}

export default new PaymentProcessorService();