import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useFetchDoors } from './redux/hooks';
import { Box, Heading, Button } from 'grommet';
import { Download } from 'grommet-icons';
import { SettingsDoorTable } from "./";
import { PmsService } from "../../services";
import { presentLoading, dismissLoading } from '../common/Loading';

export default function SettingsRoomsAreas() {
  const { rooms, areas, fetchDoors } = useFetchDoors();

  useEffect(() => {
    fetchDoors();
  }, [fetchDoors]);

  const handleImport = () => {
    presentLoading();
    PmsService.importDoors().then(
      (response) => {
        fetchDoors();
        dismissLoading();
      },
      (error) => {
        dismissLoading();
      }
    );
  }

  return (
    <div>
      <Box direction="row" justify="between">
        <Heading level="2">Door Locks</Heading>
        <Button style={{maxHeight: 30}} alignSelf="center" icon={<Download size="small"/>} label="Import from PMS" primary color="status-warning" onClick={handleImport}/>
      </Box>
      <Box direction="row" gap="large">
        <SettingsDoorTable title="Rooms" type="room" doors={rooms} onRefresh={fetchDoors}  />
        <SettingsDoorTable title="Areas" type="area" doors={areas} onRefresh={fetchDoors}  />
      </Box>
    </div>
  );
};

SettingsRoomsAreas.propTypes = {};
SettingsRoomsAreas.defaultProps = {};
