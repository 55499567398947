import axios from 'axios';
import { API_URL, authHeader } from './api';

const ENDPOINT_URL = API_URL + 'notif/';

class NotificationService {

  removeMessage(bookingId) {
    return axios.delete(ENDPOINT_URL + bookingId + "/message", { headers: authHeader() }, );
  }
  
}

export default new NotificationService();