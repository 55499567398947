
import React, { useEffect, useState } from 'react';
import { Box, Button, TextInput, Heading, Layer, CheckBox, FormField, Select, Text } from "grommet";
import { presentLoading, dismissLoading } from '../common/Loading';
import { presentToastSuccess, presentToastErrorContent } from '../common/Toast';
import { UserService, AuthService } from "../../services";
import { PhoneInput } from '../common/';
import { parsePhoneNumber } from 'react-phone-number-input';

const defaultValues = {
  username:"",
  email:"", 
  phone:"", 
  notifEmail:true,
  notifText:true
}

export default function AddUser(props) {
  const { userId, onClose, currentUser } = props;
  const [user, setUser] = useState(defaultValues);
  const [ phoneNumberError, setPhoneNumberError ] = useState("");

  useEffect(() => {
    if (userId !== 0)
    {
      presentLoading();
      UserService.getUserById(userId).then(
        (response) => {
          dismissLoading();
          if (response.data && response.data.id)
          {
            setUser({...defaultValues, ...response.data});
          }
        },
        (error) => {
          dismissLoading();
          presentToastErrorContent(error);
        }
      );
    }
    else
    {
      setUser(defaultValues);
    }
  }, [userId]);

  function handleInputChange({target:{name, value}}) {
    var values = {...user};
    values[name] = value;
    setUser(values);
  }

  const handleAdd = () =>
  {
    if (userId === 0)
    {
      AuthService.register(user).then(
        async (response) => {
            onClose(true);
            presentToastSuccess("Success");
        },
        (error) => {
          presentToastErrorContent(error);
        }
      );
    }
    else
    {
      UserService.update(user).then(
        async (response) => {
            onClose(true);
            presentToastSuccess("Success");
        },
        (error) => {
          presentToastErrorContent(error);
        }
      );      
    }
  }

  function handleCheckboxChange({target:{name, checked}}) {
    var values = {...user};
    values[name] = checked;
    setUser(values);
  }

  function handlePhoneInputChange(phone) {
    setPhoneNumberError("");
    let phoneObj = phone && parsePhoneNumber(phone);
    let areacode = '+' + ((phoneObj && phoneObj.countryCallingCode) || "");
    setUser({...user, phone, areacode});
  }

  return (
        <Layer position="center" onClickOutside={onClose} onEsc={onClose}>
          <Box pad="medium" gap="small" width="large">
            <Heading level={3} margin="none">
              Add User
            </Heading>
            <Box direction="row" gap="small">
              <FormField label="Email" fill>
                <TextInput value={user.email} name="email" onChange={handleInputChange} />
              </FormField>
              <FormField label="Role" fill>
              <Select
                  name="role"
                  placeholder="Select role"
                  value={user.role}
                  options={['user', 'manager']}
                  onChange={({ value: nextValue }) => {
                      setUser({...user, role:nextValue});
                    }
                  }
                />
               </FormField> 

            </Box>
            <Box direction="row" gap="small">
              <FormField label="Username" width="medium">
                <TextInput value={user.username} name="username" onChange={handleInputChange} />
              </FormField>
              <FormField label="Phone Number" className="phoneInputField" error={phoneNumberError}>
                <PhoneInput value={user.phone} onChange={handlePhoneInputChange} />
                <div className="international">{user.phone}</div>
              </FormField>
            </Box>   
            <Text>Receive notifications</Text>
            <Box direction="row" gap="small">
              <CheckBox name="notifEmail"
                  checked={user.notifEmail}
                  onChange={handleCheckboxChange}
                  label="Email"/>
              <CheckBox name="notifText"
                  checked={user.notifText}
                  onChange={handleCheckboxChange}
                  label="Text"/>
            </Box>
            <Box as="footer" gap="small" direction="row" align="center"
              justify="end" pad={{ top: "medium", bottom: "small" }}
            >
              <Button label="Cancel" onClick={onClose} color="dark-3" />
              <Button label={userId===0?"Add":"Update"} onClick={handleAdd} primary color="status-ok" />
            </Box>
          </Box>
        </Layer>
  );
};

AddUser.propTypes = {};
AddUser.defaultProps = {};