import React, { useEffect, useState, useRef } from 'react';
// import PropTypes from 'prop-types';
import { Box, Button, TextArea, Text, Image } from 'grommet';
import { Refresh } from 'grommet-icons';
import moment from 'moment-timezone';
import { CommunicationService } from "../../services";
import { presentToastSuccess, presentToastErrorContent } from '../common/Toast';

export default function Conversation(props) {

  const [currentMessage, setCurrentMessage] = useState("");
  const {bookingId, messages, hotel} = props;
  const [messagesList, setMessageList] = useState(messages || []);

  const messageEndRef = useRef(null)

  const scrollToBottom = () => {
    if (messageEndRef)
      messageEndRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
  }

  useEffect(()=>{
    setMessageList(messages || []);
    setTimeout(scrollToBottom, 1000);
  }, [messages]);

  const handleSendMessage = () => {
    CommunicationService.sendMessage(bookingId, currentMessage).then(
      (response) => {
        if (response.data)
        {
          setCurrentMessage("");
          setMessageList([...messagesList, response.data]);
        }
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  return (
    <Box gap="small" width="100%">
      <Box height="medium" round width="xlarge" overflow="scroll"
            pad="medium" margin="medium" background="light-1">
          <Box flex="grow" >
            {messagesList && messagesList.map((message)=>
              <Box key={message._id}>
                {message.photo && 
                  <Image className="photo" 
                          src={message.photo} fit="cover" 
                          width="200px" round="small" alignSelf={message.fromHotel ? "end" : "start"} />
                }
                {message.text && 
                  <Box className="message" width="fit-content"
                        alignSelf={message.fromHotel ? "end" : "start"} 
                        background={message.fromHotel ? "accent-1" : "light-4"} 
                        round="small" pad="small">
                    {message.text}
                  </Box>
                } 
                <Text className="date" size="11px" style={{fontStyle:'italic'}} alignSelf={message.fromHotel ? "end" : "start"}>
                    {moment.tz(message.created, hotel.timezone).format("llll")}
                </Text>
              </Box>
            )}
            </Box>
            <div ref={messageEndRef}></div>
      </Box>
      <Box direction="row">
        <Box
          background="light-1"
          margin="small"
          animation="fadeIn"
          height="100px"
          width="100%"
        >
          <TextArea
            fill
            value={currentMessage}
            onChange={event => setCurrentMessage(event.target.value)}
            placeholder="Type your message here..."
            resize={false}
          />
        </Box>
        <Button label="Send" onClick={handleSendMessage} primary alignSelf="center"/>
      </Box>
    </Box>
  );
};

Conversation.propTypes = {};
Conversation.defaultProps = {};
