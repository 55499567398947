import axios from 'axios';
import { API_URL, authHeader } from './api';

const ENDPOINT_URL = API_URL + 'door/';

class DoorService {
  getDoors() {
    return axios.get(ENDPOINT_URL + "list", { headers: authHeader() });
  }

  getDoorsByHotel(hotelSlug) {
    return axios.get(ENDPOINT_URL + "list/" + hotelSlug, { headers: authHeader() }, );
  }

  //doors : [{name:<string>, type:<string>, lockId?:<string>, lockBattery?:<string> }, ...]
  addDoors(doors) {
    return axios.post(ENDPOINT_URL, doors, { headers: authHeader() });
  }

  //doors : [{name:<string>, type:<string>, lockId?:<string>, lockBattery?:<string> }, ...]
  addDoorsByHotel(doors, hotelSlug) {
    return axios.post(ENDPOINT_URL + "/" + hotelSlug, doors, { headers: authHeader() });
  }

  deleteDoor(doorId) {
    return axios.delete(ENDPOINT_URL + '/' + doorId, { headers: authHeader() });
  }

  //doorIds : [<string>, <string>, ...]
  deleteDoors(doorIds) {
    return axios.post(ENDPOINT_URL + 'deletemany', doorIds, { headers: authHeader() });
  }

  //doorIds : [<string>, <string>, ...]
  toggleDoors(doorIds) {
    return axios.post(ENDPOINT_URL + 'toggle', doorIds, { headers: authHeader() });
  }
  

  getRoomTypes() {
    return axios.get(ENDPOINT_URL + "room-types", { headers: authHeader() });
  }

}

export default new DoorService();