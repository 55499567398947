import React, {useEffect, useState, useMemo} from 'react';
// import PropTypes from 'prop-types';
import PhotoService from "../../services/photo.service";

export default function SecuredImage(props) {
  const  {filename, ...rest } = props;
  const [ image, setImage ] = useState("");

  const memoizedObj = useMemo(() => PhotoService.getSecuredURL(filename), [filename]);

  useEffect(()=>{
    fetch(memoizedObj.url, {headers:memoizedObj.headers}).then(r=>r.blob()).then(d=> setImage(window.URL.createObjectURL(d)));
  }, [memoizedObj]);

  return (
    <img alt="" src={image} {...rest} />
  );
};

SecuredImage.propTypes = {};
SecuredImage.defaultProps = {};
