import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  DASHBOARD_FETCH_HOTEL_BEGIN,
  DASHBOARD_FETCH_HOTEL_SUCCESS,
  DASHBOARD_FETCH_HOTEL_FAILURE,
  DASHBOARD_FETCH_HOTEL_DISMISS_ERROR,
} from './constants';
import HotelService from "../../../services/hotel.service";
import { presentToastLink, TYPE_ERROR } from '../../common/Toast';

export function fetchHotel(args = {}) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: DASHBOARD_FETCH_HOTEL_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      HotelService.getHotel().then(
        (res) => {
          dispatch({
            type: DASHBOARD_FETCH_HOTEL_SUCCESS,
            data: res.data,
          });
          if (!!res.data.renewSubscription)
            presentToastLink("Your LOXE subscription has expired, please [update your payment method] to avoid service interruption.", res.data.renewSubscription, TYPE_ERROR);
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: DASHBOARD_FETCH_HOTEL_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchHotelError() {
  return {
    type: DASHBOARD_FETCH_HOTEL_DISMISS_ERROR,
  };
}

export function useFetchHotel() {
  const dispatch = useDispatch();

  const { hotel, fetchHotelPending, fetchHotelError } = useSelector(
    state => ({
      hotel: state.dashboard.hotel,
      fetchHotelPending: state.dashboard.fetchHotelPending,
      fetchHotelError: state.dashboard.fetchHotelError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchHotel(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchHotelError());
  }, [dispatch]);

  return {
    hotel,
    fetchHotel: boundAction,
    fetchHotelPending,
    fetchHotelError,
    dismissFetchHotelError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_FETCH_HOTEL_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchHotelPending: true,
        fetchHotelError: null,
      };

    case DASHBOARD_FETCH_HOTEL_SUCCESS:
      // The request is success
      return {
        ...state,
        hotel: action.data,
        fetchHotelPending: false,
        fetchHotelError: null,
      };

    case DASHBOARD_FETCH_HOTEL_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchHotelPending: false,
        fetchHotelError: action.data.error,
      };

    case DASHBOARD_FETCH_HOTEL_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchHotelError: null,
      };

    default:
      return state;
  }
}
