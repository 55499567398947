import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Box, Form, FormField, TextInput, Button, Select, Heading} from 'grommet';
import { STATES_OPTIONS_LIST } from "../../common/utils";
import HotelService from "../../services/hotel.service";
import PhotoService from "../../services/photo.service";
import { presentToastSuccess, presentToastErrorContent } from '../common/Toast'
import { PhoneInput } from '../common/';
import { PhotoUploader } from './';
import { useFetchHotel } from './redux/hooks';
//import { parsePhoneNumber } from 'react-phone-number-input'

const defaultValues = {
  name:"",
  address:"",
  city:"",
  state:"",
  zipCode:"",
  country:"",
  email:"",
  phone:"",
  cover:"",
  logo:"",
  deposit:200
}

export default function SettingsHotelInfo(props) {
  const { hotel, fetchHotel } = useFetchHotel();
  const [formValues, setFormValues] = useState(defaultValues);
  const [statesOptions, setStatesOptions] = useState(STATES_OPTIONS_LIST);

  useEffect(() => {
    setFormValues(hotel || defaultValues);
  }, [hotel]);

  const handleCancel = () =>
  {
    setFormValues(hotel || defaultValues);
  }

  function handleInputChange({target:{name, value}}) {
    var values = {...formValues};
    values[name] = value;
    setFormValues(values);
  }

  function handlePhoneInputChange(phone) {
    //let phoneObj = phone && parsePhoneNumber(phone);
    //let areacode = '+' + ((phoneObj && phoneObj.countryCallingCode) || "");
    //console.log(phone);
    setFormValues({...formValues, phone});
  }

  const handleOnSubmit = (e) =>
  {
    e.preventDefault();
    //var phone = formValues.phone && formValues.phone.replace(/\D/g,'');
    //var updateData = {...formValues, phone};
    HotelService.updateHotel(formValues).then(
      (response) => {
        if (response.data.ok)
        {
          fetchHotel();
          presentToastSuccess("Information Updated");
        }
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  const handleUploadCover = (file) =>
  {
    HotelService.uploadCover(file).then(
      (response) => {
        if (response.data.success)
        {
          setFormValues({...formValues, cover:response.data.filename});
          presentToastSuccess("Photo Updated");
        }
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  const handleUploadLogo = (file) =>
  {
    HotelService.uploadLogo(file).then(
      (response) => {
        if (response.data.success)
        {
          setFormValues({...formValues, logo:response.data.filename});
          presentToastSuccess("Logo Updated");
        }
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  return (
    <Box fill align="center" justify="center">
      <Box width="large">
        <Heading alignSelf="start" level="2">Hotel Information</Heading>
        <Form >
        <Box direction="row" justify="center">
          <PhotoUploader label="Cover photo" onChange={handleUploadCover} fitWidth={980} fitHeight={600} photo={PhotoService.getURL(formValues.cover)}/>
          <PhotoUploader blackbg label="Logo" onChange={handleUploadLogo} fitWidth={400} fitHeight={400} photo={PhotoService.getURL(formValues.logo)}/>
        </Box>
          <FormField label="Name">
              <TextInput name="name" value={formValues.name || ""} onChange={handleInputChange} />
          </FormField>
          <FormField label="Address">
              <TextInput name="address" value={formValues.address || ""} onChange={handleInputChange} />
          </FormField>
          <FormField label="City">
              <TextInput name="city" value={formValues.city || ""} onChange={handleInputChange} />
          </FormField>
          <Box direction="row" gap="small">
            <FormField label="State">
                <Select
                    size="medium"
                    labelKey="label"
                    valueKey={{ key: "value", reduce: true }}
                    placeholder="Select State"
                    value={formValues.state || ""}
                    options={statesOptions}
                    onChange={({ value: nextValue }) => setFormValues({...formValues, state:nextValue})}
                    onClose={() => setStatesOptions(STATES_OPTIONS_LIST)}
                    onSearch={text => {
                      const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
                      const exp = new RegExp(escapedText, "i");
                      setStatesOptions(STATES_OPTIONS_LIST.filter(o => (exp.test(o.value) || exp.test(o.label))));
                    }}
                  />
            </FormField>
            <FormField label="Zip Code" alignSelf="end">
                <TextInput name="zipCode" value={formValues.zipCode || ""} onChange={handleInputChange} />
            </FormField>
            <FormField label="Country" alignSelf="end">
                <TextInput name="country" value={formValues.country || ""} onChange={handleInputChange} />
            </FormField>
          </Box>
          <Box direction="row" gap="small">
            <FormField label="Email" fill >
              <TextInput name="email" value={formValues.email || ""} onChange={handleInputChange} placeholder="example@my.com"/>
            </FormField>
            <FormField label="Phone number" fill className="phone-field">
              <PhoneInput  name="phone" value={formValues.phone || ""} onChange={handlePhoneInputChange} />
            </FormField>
          </Box>

          <Box direction="row" justify="between" margin={{ top: "medium" }}>
            <Button label="Cancel" onClick={handleCancel}/>
            <Button type="submit" label="Update" primary onClick={handleOnSubmit} />
          </Box>
        </Form>
      </Box>
    </Box>
  );
};

SettingsHotelInfo.propTypes = {};
SettingsHotelInfo.defaultProps = {};
