import React from 'react';
// import PropTypes from 'prop-types';
import { Box, Text, Image } from 'grommet';

export default function NoResults({children, ...rest}) {
  return (
    <Box alignSelf="center" width="large" pad="large" {...rest}>
              <Text alignSelf="center" style={{marginTop:100, textAlign:'center'}} >{children}</Text>
              <Image style={{maxWidth:"600px"}}
                  fit="cover" 
                  src={require('../../images/no-results.svg')} 
                  className="no-results-image" />
            </Box>
  );
};

NoResults.propTypes = {};
NoResults.defaultProps = {};
