import { API_URL, authHeader } from './api';

const ENDPOINT_URL = API_URL + 'image/';

class PhotoService {
  getURL(filename) {
    if (filename && filename.startsWith("http")) return filename;
    return (filename) ? ENDPOINT_URL + filename : undefined;
  }

  getSecuredURL(filename) {
    return (filename) ? { headers: authHeader(), url: ENDPOINT_URL + "s4h/" + filename} : {};
  }
}
export default new PhotoService();