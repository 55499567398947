import axios from 'axios';
import { API_URL, authHeader } from './api';

const ENDPOINT_URL = API_URL + 'user/';

class UserService {
  getUser() {
    return axios.get(ENDPOINT_URL + 'me', { headers: authHeader() });
  }

  getUsers() {
    return axios.get(ENDPOINT_URL + 'list', { headers: authHeader() });
  }

  deleteUser(userId) {
    return axios.delete(ENDPOINT_URL + userId, { headers: authHeader() });
  }

  update(user) {
    return axios.patch(ENDPOINT_URL, user, { headers: authHeader() });
  }

  getUserById(userId) {
    return axios.get(ENDPOINT_URL + userId, { headers: authHeader() });
  }

  addPushId(pushId) 
  {
    return axios.post(ENDPOINT_URL + "add-push", {pushId}, { headers: authHeader() });
  }
}

export default new UserService();