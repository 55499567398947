import firebase from 'firebase/app';
import 'firebase/messaging';

var firebaseConfig = {
    apiKey: "AIzaSyD-ZddmcaUCQh1mRC_cBHDIldMzAfe22xQ",
    authDomain: "loxe-frontdesk.firebaseapp.com",
    projectId: "loxe-frontdesk",
    storageBucket: "loxe-frontdesk.appspot.com",
    messagingSenderId: "677798054388",
    appId: "1:677798054388:web:22fd21e666695d337311a1",
    measurementId: "G-FHE2SVTFSQ"
  };

  var messaging;

  if (process.env.NODE_ENV == 'production')
  {
    firebase.initializeApp(firebaseConfig);  
    try {
      messaging = firebase.messaging();
    } catch (e) {}
  }

  const FIREBASE_MESSAGING_KEY = "BLXdoGQiSOfP3EdrcZfGYuinpPJlFSRShOJKO4yoccJhlhu8cDmalRTRvu-t5IO9j0fXd7qpJ3VAePf6z-R9Lqo";

  export const getFirebaseToken = (setPushId) => {
    if (!messaging) return;

    return messaging.getToken({vapidKey: FIREBASE_MESSAGING_KEY}).then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        setPushId(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setPushId(undefined);
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
  }

  export const onFirebaseMessageListener = () =>
    new Promise((resolve) => {
      if (!messaging) return;

      messaging.onMessage((payload) => {
        resolve(payload);
      });
  });
