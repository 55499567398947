import React, { useEffect, useState } from 'react';
import { Box, Button, TextInput, Heading, Layer, CheckBox, FormField } from "grommet";
import { presentLoading, dismissLoading } from '../common/Loading';
import { presentToastSuccess, presentToastErrorContent } from '../common/Toast';
import CategoryService from "../../services/category.service";
import PhotoService from "../../services/photo.service";
import { PhotoUploader, ValidStatusesSelect } from './';
import { PriceInput } from '../common';

const defaultValues = {
  _id:0,
  name:"",
  feeLabel:"",
  fee:0,
  photo:"", 
  isMenu:false,
  taxPercent:"",
  validStatuses:["prearrival", "checkedin"]
}

export default function AddCategory(props) {
  const { categoryId, onClose } = props;
  const [category, setCategory] = useState(defaultValues);
  const [photo, setPhoto] = useState(undefined);

  function handleInputChange({target:{name, value}}) {
    var values = {...category};
    values[name] = value;
    setCategory(values);
  }

  function handleCheckboxChange({target:{name, checked}}) {
    var values = {...category};
    values[name] = checked;
    setCategory(values);
  }

  const handleAdd = () =>
  {
    let validStatuses = [];
    if (category.validStatuses.length > 0)
    {
      validStatuses = category.validStatuses[0] && category.validStatuses[0].id ? category.validStatuses.map(h=>h.id) : category.validStatuses;
    }
    if (categoryId === 0)
    {
      CategoryService.addCategory({...category, validStatuses}).then(
        async (response) => {
            const category = response.data && response.data.category;
            if (category)
              await uploadPhoto(category._id);
            onClose(true);
            presentToastSuccess("Success");
        },
        (error) => {
          presentToastErrorContent(error);
        }
      );
    }
    else
    {
      CategoryService.updateCategory({...category, validStatuses}).then(
        async (response) => {
            await uploadPhoto(categoryId);
            onClose(true);
            presentToastSuccess("Success");
        },
        (error) => {
          presentToastErrorContent(error);
        }
      );
    }
  }

  const uploadPhoto = async (categoryId) =>
  {
    if (!photo) return;
    await CategoryService.uploadPhoto(categoryId, photo).then(
      (response) => {
        if (response.data.success)
        {
          //presentToastSuccess("Photo Updated");
        }
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  useEffect(() => {
    if (categoryId !== 0)
    {
      presentLoading();
      CategoryService.getCategory(categoryId).then(
        (response) => {
          dismissLoading();
          const category = response.data;
          if (category._id)
          {
            setCategory(category);
          }
        },
        (error) => {
          dismissLoading();
          presentToastErrorContent(error);
        }
      );
    }
    else
    {
      setCategory(defaultValues);
    }
  }, [categoryId]);

  return (
        <Layer position="center" onClickOutside={()=>onClose(false)} onEsc={onClose}>
          <Box pad="medium" gap="small" width="large">
            <Box direction="row" justify="between">
              <Heading level={3} margin="none">
                Add Category
              </Heading>
              <CheckBox name="active" toggle
                  checked={category.active} 
                  onChange={handleCheckboxChange} 
                  label="Active"/>
            </Box>
            <Box direction="row"  gap="medium">
              <Box gap="medium">
                <FormField label="Name" width="medium">
                  <TextInput name="name" value={category.name} onChange={handleInputChange} />
                </FormField>
                <Box direction="row" gap="small">
                  <FormField label="Fee Label" >
                    <TextInput name="feeLabel" value={category.feeLabel} onChange={handleInputChange} />
                  </FormField>
                  <FormField label={"Fee Amount"} >
                    <PriceInput style={{border:0}} name="fee" value={category.fee} onChange={handleInputChange} />
                  </FormField>
                </Box>
                <CheckBox name="isMenu"
                  checked={category.isMenu}
                  onChange={handleCheckboxChange}
                  label="Show as a menu"/>
                {
                  category.isMenu &&
                  <div>
                    <FormField label="Tax Rate (%)" >
                        <TextInput name="taxPercent" value={category.taxPercent} onChange={handleInputChange} />
                    </FormField>
                    <FormField label="Valid Statuses">
                        <ValidStatusesSelect name="validStatuses" 
                                             currentValue={category.validStatuses} 
                                             onChange={handleInputChange} />
                    </FormField>
                  </div>
                }
              </Box>
              <Box alignSelf="center">
                <PhotoUploader label="Photo (jpg, 980 × 600, <350K)" fitWidth={980} fitHeight={600} onChange={setPhoto} photo={PhotoService.getURL(category.photo) || ""}/>
              </Box>
            </Box>
            <Box as="footer" gap="small" direction="row" align="center"
              justify="end" pad={{ top: "medium", bottom: "small" }}
            >
              <Button label="Cancel" onClick={()=>onClose(false)} color="dark-3" />
              <Button label={categoryId===0?"Add":"Update"} onClick={handleAdd} primary color="status-ok" />
            </Box>
          </Box>
        </Layer>
  );
};

AddCategory.propTypes = {};
AddCategory.defaultProps = {};