import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Text, Box } from 'grommet';

export default function DoorStatus(props) {
  const { status } = props;

  const [color, setColor] = useState("dark-6");
  const [label, setLabel] = useState("");
  
  useEffect(()=>{
    switch (status)
    {
      case "CLEAN":
        setColor("status-ok");
        setLabel("Clean");
        break;
      case "DIRTY":
        setColor("status-warning");
        setLabel("Dirty");
        break;
      case "INSPECTED":
        setColor("neutral-3");
        setLabel("Inspected");
        break;
      case "NOT_AVAILABLE":
        setColor("status-error");
        setLabel("Unavailable");
        break;
      default:
        setLabel("");
    }
    

  }, [status]);

  return (
    label !== "" &&
      <Box style={{width: 'fit-content', margin: 'auto'}} pad={{horizontal:'small'}} align="center" round="small" border={{ color:color, size: 'small' }}>
        <Text size="11px" color={color}>{label}</Text>
      </Box>
  );
};

DoorStatus.propTypes = {};
DoorStatus.defaultProps = {};
