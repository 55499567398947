import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useFetchRoomTypes } from './redux/hooks';
import { Select, Box,Text, TextInput } from 'grommet';

export default function RoomTypeSelect(props) {
  const { roomTypes } = useFetchRoomTypes();
  const [options, setOptions] = useState(roomTypes || []);
  const [value, setValue] = useState("");
  const [disabledLabel, setDisabledLabel ] = useState("");
  const { disabled } = props; 

  useEffect(()=>{
    if (disabled && value)
    {
      var v=roomTypes.find(x=>x._id===value);
      setDisabledLabel(v.name);
    }
  }, [value, disabled]);

  useEffect(() => {
    setValue(props.value);
    setOptions(roomTypes || []);
  }, [props.value, roomTypes]);

  function handleChange({ option })
  {
    setValue(option._id);
    props.onChange({target:{name:props.name, value:option._id}});
  }

  const renderOption = (option, state) => {
    return <Box pad="small" justify="between"
                background={state.active ? "active" : (option._id===value ? "brand" : undefined)} 
                direction="row">
      <Text>{option.name}</Text>
    </Box>
  };

  return (
    !disabled ? 
      <Select
        plain
        size="medium"
        placeholder="Select"
        value={value || ""}
        labelKey="name"
        valueKey={{ key: "_id", reduce: true }}
        dropHeight="medium"
        options={options}
        onChange={handleChange}
        onClose={() => setOptions(roomTypes)}
        onSearch={text => {
          // The line below escapes regular expression special characters:
          // [ \ ^ $ . | ? * + ( )
          const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");

          // Create the regular expression with modified value which
          // handles escaping special characters. Without escaping special
          // characters, errors will appear in the console
          const exp = new RegExp("^("+escapedText+")", "i");
          setOptions(roomTypes.filter(o => exp.test(o.name)));
        }}
      >{renderOption}</Select>
      :
      <TextInput plain value={disabledLabel || "" } />
  );
};

RoomTypeSelect.propTypes = {};
RoomTypeSelect.defaultProps = {};
