import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Box, Header, Heading, DataTable, Button, CheckBox, Meter, Text } from 'grommet';
import { Trash, Trigger, Lock, Action } from 'grommet-icons';
import { onDatatableSearch } from "../../common/utils";
import { presentToastSuccess, presentToastErrorContent, presentToastWarning } from '../common/Toast'
import { AddDoor } from './';
import DoorService from "../../services/door.service";
import { presentAlertPrompt } from '../common/Alert';
import { useFetchHotel } from './redux/hooks';


export default function SettingsDoorTable(props) {
  const { title, type, doors, onRefresh } = props;
  const [ checked, setChecked ] = useState([]);
  const [ list, setList ] = useState(doors || []);
  const [sort, setSort] = useState({property: "name",direction: "asc"});
  const { hotel } = useFetchHotel();
  
  const onCheck = (event, value) => {
    if (event.target.checked) {
      setChecked([...checked, value]);
    } else {
      setChecked(checked.filter(item => item !== value));
    }
  };

  const onCheckAll = ({target:{checked}}) => {
    if (list.length)
      setChecked(checked ? list.map(datum => datum._id) : []);
  };
  
  const columns = [
  {
    property: "checkbox",
    render: datum => (
      <CheckBox
        key={datum._id}
        checked={checked.indexOf(datum._id) !== -1}
        onChange={e => onCheck(e, datum._id)}
      />
    ),
    header: (
      <CheckBox
        checked={checked.length === list.length}
        indeterminate={
          checked.length > 0 && checked.length < list.length
        }
        onChange={onCheckAll}
      />
    ),
    sortable: false
  },
  {
    property: 'name',
    header: "Name",
    primary:true,
    search:true,
    sortable: true,
    render: (datum) => (!datum.disabled) ? datum.name : 
                      <Text weight="bold" color="status-error"
                            style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>
                      {datum.name}
                    </Text>
  }];
  if (type === "room")
    columns.push(
    {
      property: 'roomType',
      header: "Type",
      search:true,
      sortable: true,
      render: (datum) => (!!datum.roomType) ? <Text truncate size="small">{datum.roomType.name}</Text> : "-"
    });

  columns.push({
    property: 'lockBattery',
    header: 'Mobile Key',
    render: (datum) => (datum.lockId) ? 
      (hotel.disableBatteryCheck ? 
        <Box align="center"><Lock size="small" /></Box>
      :
      <Box align="center" direction="row">
        <Trigger size="small"/>
        <Box width="20px" margin={{"horizontal":"5px"}}>
          <Meter
              values={[{ value: datum.lockBattery, 
                    color:datum.lockBattery>=70 ? "status-ok" : (datum.lockBattery>=20 ? "status-warning" : "status-error") }]}
              thickness="small"
              size="small"
              background="light-6"
            />
          </Box> 
          <Text size="small">{Math.round(datum.lockBattery*10)/10}%</Text>
      </Box> )
      : "-",
    align:"center",
    sortable: true
  });

  useEffect(() => {
    setList(doors || []);
  }, [doors]);

  
  const handleAddDoor = (doors) => 
  {
    DoorService.addDoors(doors).then(
      (response) => {
          onRefresh();
          presentToastSuccess("Success");
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  const handleRemoveDoor = () => {
        presentAlertPrompt({title:"Are you sure?", 
                        message:"If there is a BLE Module in a removed door, you will have to reset it. This won't delete currently used doors.", 
                        onOK:removeDoors, 
                        buttonOKText:"Delete",
                        buttonOKColor:"status-critical"
    });
  };

  const removeDoors = () => {
    DoorService.deleteDoors(checked).then(
      (response) => {
          onRefresh();
          setChecked([]);
          if (response.data.doorsInUse.length > 0)
            presentToastWarning("One or more items couldn't be removed because they are in use.");
          else
            presentToastSuccess("Success");
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  const handleToggleDoor = () => {
        presentAlertPrompt({title:"Are you sure?", 
                        message:"This will only affect doors that has a lock. A disabled lock means " +
                                "that no guests in this room will receive an online check-in invitation, " + 
                                "and no key for this room will be sent automatically.", 
                        onOK:toggleDoors, 
                        buttonOKText:"Disable/Enable",
                        buttonOKColor:"status-warning"
    });
  };

  const toggleDoors = () => {
    DoorService.toggleDoors(checked).then(
      (response) => {
          onRefresh();
          setChecked([]);
          presentToastSuccess("Success");
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  return (
      <Box >
        <Header align="center" direction="row" justify="between" gap="medium" fill="horizontal">
          <Heading level="4">{title}</Heading>
          <AddDoor type={type} onSubmit={handleAddDoor} />
          <Button icon={<Action size="small" />} size="small" label="Enable/Disable" 
                  primary color="status-warning" onClick={handleToggleDoor}
                  disabled={!checked.length}/>
          <Button icon={<Trash size="small" />} size="small" label="Remove" 
                  primary color="status-critical" onClick={handleRemoveDoor}
                  disabled={!checked.length}/>
        </Header>
        <Box align="center" fill="horizontal" >
          <DataTable
          columns={columns}
          data={list}
          primaryKey="id"
          sort={sort}
          onSort={setSort}
          onSearch={(search)=>onDatatableSearch(search, doors, setList)}
        />
        </Box>
      </Box>
  );
};

SettingsDoorTable.propTypes = {};
SettingsDoorTable.defaultProps = {};
