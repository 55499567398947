import React, { useState } from 'react';
import { Box, Button, TextInput, Heading, Layer, Text, Select } from "grommet";
import { Add } from 'grommet-icons';
import { useFetchRoomTypes } from './redux/hooks';

export default function AddDoor(props) {
  const { type, onSubmit } = props;
  const [newDoors, setNewDoors] = useState("");
  const [open, setOpen] = useState();
  const [roomType, setRoomType] = useState(0);
  const { roomTypes } = useFetchRoomTypes();

  const onOpen = () => {setNewDoors("");setOpen(true);}
  const onClose = () => setOpen(undefined);

  function handleInputChange({target:{name, value}}) {
    setNewDoors(value);
  }
  const handleAdd = () =>
  {
    var doors = newDoors.split(",").map((str)=>{return {name:str.trim(), type, roomType }});
    onClose();
    onSubmit(doors);
  }

  return (
    <div>
      <Button primary icon={<Add size="small" />} size="small" label="Add" onClick={onOpen} />
      {open && (
        <Layer position="center" onClickOutside={onClose} onEsc={onClose}>
          <Box pad="medium" gap="small" width="medium">
            <Heading level={3} margin="none">
              Add {type}
            </Heading>
            <Text>Enter a {type} name. (You can enter multiple names by separating them with commas)</Text>
            <TextInput value={newDoors} onChange={handleInputChange} />
            {
              type === "room" &&
              <Select
                name="roomType"
                placeholder="Select the type of room"
                value={ roomType }
                labelKey="name"
                valueKey={{ key: "_id", reduce: true }}
                options={roomTypes}
                onChange={({ value: nextValue }) => setRoomType(nextValue)}
              />
              }
            <Box as="footer" gap="small" direction="row" align="center"
              justify="end" pad={{ top: "medium", bottom: "small" }}
            >
              <Button label="Cancel" onClick={onClose} color="dark-3" />
              <Button label="Add" onClick={handleAdd} primary color="status-ok"
              />
            </Box>
          </Box>
        </Layer>
      )}
    </div>
  );
};

AddDoor.propTypes = {};
AddDoor.defaultProps = {};
