import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useFetchDoors } from './redux/hooks';
import { Box, Select, Button, Text } from 'grommet';
import { FormClose } from 'grommet-icons';
import { presentAlertPrompt } from '../common/Alert';

export default function DoorsSelect(props) {
  
  const { currentValue, suggestedRoom } = props;
  const { rooms, areas } = useFetchDoors();
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(()=>{
    if (!areas || !rooms) return;
    const allDoors = areas.concat(rooms.sort((a, b)=>(a.name<b.name?-1:1)));
    const suggestedSelected = allDoors.filter(door => (door.lockId && (door.name === suggestedRoom || door.type==="area")));
    const defaultSelected = (!props.value || !props.value.length) ? (suggestedSelected || []) : props.value;
    // const suggestedRoomObj = allDoors.find(x=>x.lockId && x.name===suggestedRoom);
    // if (suggestedRoomObj && !defaultSelected.find(x=>x.name===suggestedRoom))
    // {
      //const nextSelected = [...defaultSelected, suggestedRoomObj];
      setOptions(allDoors);
      setSelected(defaultSelected);
      props.onChange({target:{name:props.name, value:defaultSelected}});
    // }
  }, [suggestedRoom]);

  // useEffect(()=>{
  //   if (!props.value || !props.value.length) 
  //   {
  //     setTimeout(()=>{
  //       props.onChange({target:{name:props.name, value:defaultSelected}});}, 500);
  //   }
  // }, []);

  const onRemoveDoor = door => {
    if (!door.shared)
    {
      const nextSelected = selected.filter(selectedDoor => selectedDoor._id !== door._id);
      setSelected(nextSelected);
      props.onChange({target:{name:props.name, value:nextSelected}});
    }
    else
    {
      presentAlertPrompt({title:"Shared Key", 
                        message:"This key has been shared by another guest. You can remove this key by removing the parent guest's key.", 
      });
    }
  };

  const renderDoor = door => (
    door && <Button
      key={`door_tag_${door._id}`}
      href="#"
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
        onRemoveDoor(door);
      }}
      onFocus={event => event.stopPropagation()}
      style={{ position:'relative' }}
    >
      <Box
        align="center"
        direction="row"
        gap="xsmall"
        pad={{ vertical: "xsmall", horizontal: "small" }}
        margin="xsmall"
        background={(currentValue.find(x => x._id === door._id)) 
                                ? "status-ok"
                                : (door.lockId ? "brand" : "status-error")}
        round="large"
      >
        <Text size="small" color="white">
          {door.name}
        </Text>
        <Box background="white" round="full" margin={{ left: "xsmall" }}>
          <FormClose
            color={(currentValue.find(x => x._id === door._id)) 
                                ? "status-ok"
                                : (door.lockId ? "brand" : "status-error")}
            size="small"
            style={{ width: "12px", height: "12px" }}
          />
        </Box>
      </Box>
      {
        door.shared && 
        <Box background="brand" className="shared">
          <Text color="white" size="11px">shared</Text>
        </Box>
      }
    </Button>
  );

  function handleChange({option:nextSelected})
  {
    const alreadySelected = selected.find(sel => sel._id === nextSelected._id);
    if (alreadySelected)
    {
      onRemoveDoor(nextSelected);
    }
    else
    {
      setSelected([...selected, nextSelected]);
      props.onChange({target:{name:props.name, value:[...selected, nextSelected]}});
    }
  }

  const renderOption = (option, state) => {
    const isSelected = selected.find(sel => sel._id === option._id);
    return <Box pad="small" 
                background={state.active ? "active" : (isSelected ? "brand" : undefined)} 
                direction="row" justify="between">
      <Box alignSelf="center">
        <Text size="xsmall" color="dark-6" margin={{end:"5px"}}>{option.type.toUpperCase()}</Text>
      </Box>
      <Text color={option.lockId ? 'dark-1' : 'status-error'}>{option.name}</Text>
    </Box>
  };

  return (
      <Select
        plain
        size="medium"
        placeholder="Select doors"
        value={selected}
        valueLabel={
            <Box wrap direction="row" >
              {selected && selected.length ? (
                selected.map(door => renderDoor(door))
              ) : (
                <Text color="dark-6">
                  Select Doors
                </Text>
              )}
            </Box>
          }
        closeOnChange={false}
        multiple
        dropHeight="medium"
        options={options}
        onChange={handleChange}
        labelKey="name"
        valueKey={{ key: "_id", reduce: true }}
        onClose={() => setOptions(options)}
        searchPlaceholder="Search"
        onSearch={text => {
          // The line below escapes regular expression special characters:
          // [ \ ^ $ . | ? * + ( )
          const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
          // Create the regular expression with modified value which
          // handles escaping special characters. Without escaping special
          // characters, errors will appear in the console
          const exp = new RegExp("^("+escapedText+")", "i");
          setOptions(options.filter(o => exp.test(o.name)));
        }}
      >{renderOption}</Select>
  );
};

DoorsSelect.propTypes = {};
DoorsSelect.defaultProps = {};
