import React from 'react';
import { Layer, Box, Text } from 'grommet';
import { StatusGood, StatusInfo, StatusWarning, StatusCritical } from 'grommet-icons';
import parse from 'html-react-parser';

export const TYPE_SUCCESS = "status-ok"; 
export const TYPE_INFO = "neutral-3"; 
export const TYPE_WARNING = "status-warning"; 
export const TYPE_ERROR = "status-critical"; 

class Toast extends React.Component{

    constructor(){
        super();
        this.state = {open:false, message: '', type:TYPE_INFO};
        window.toast = this;
    }

    present(message, type, delay = 3000){
      this.setState({...this.state, open:true, message:message||'', type});
      setTimeout(()=>{this.setState({...this.state, open:false});}, delay);
    }

    render(){
      return (
        this.state.open && (
            <Layer
              position={(this.state.type === TYPE_INFO)?"top":"bottom"}
              modal={false}
              margin={{ vertical: "medium", horizontal: "small" }}
              responsive={false}
              plain
            >
              <Box
                align="center"
                direction="row"
                gap="small"
                justify="between"
                round="medium"
                elevation="medium"
                pad={{ vertical: "xsmall", horizontal: "small" }}
                background={this.state.type}
              >
                <Box align="center" direction="row" gap="xsmall">
                  {(this.state.type === TYPE_SUCCESS) && <StatusGood color="white"/>}
                  {(this.state.type === TYPE_INFO) && <StatusInfo color="white"/>}
                  {(this.state.type === TYPE_WARNING) && <StatusWarning color="white"/>}
                  {(this.state.type === TYPE_ERROR) && <StatusCritical color="white"/>}
                  <Text color="white">{parse(this.state.message)}</Text>
                </Box>
              </Box>
            </Layer>
          )
      )
    }
}

export default Toast;
export function presentToastInfo(message) {window.toast.present(message, TYPE_INFO);}
export function presentToastWarning(message) {window.toast.present(message, TYPE_WARNING);}
export function presentToastError(message) {window.toast.present(message, TYPE_ERROR);}
export function presentToastSuccess(message) {window.toast.present(message, TYPE_SUCCESS);}
export function presentToastLink(message, link, type = TYPE_INFO) {
  message = message.replace(/(.*)\[(.*)\](.*)/, '$1<a href="' + link + '">$2</a>$3');
  window.toast.present(message, type, 30000);
}
export function presentToastErrorContent(error) {
  var _content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
  //if (error.response && error.response.status === 404 ) _content = "API Endpoint not found";
  presentToastError(_content);
}