import axios from 'axios';
import { API_URL, authHeader } from './api';

const ENDPOINT_URL = API_URL + 'hotel/product/';

class ProductService {

  getProducts() {
    return axios.get(ENDPOINT_URL + "all", { headers: authHeader() } );
  }

  getProductsInCategory(categoryId) {
    return axios.get(ENDPOINT_URL + "list/" + categoryId, { headers: authHeader() } );
  }

  getProduct(productId) {
    return axios.get(ENDPOINT_URL + productId, { headers: authHeader() } );
  }

  addProduct(categoryId, product) {
    return axios.post(ENDPOINT_URL + categoryId, product, { headers: authHeader() }, );
  }

  updateProduct(categoryId, product) {
    return axios.patch(ENDPOINT_URL + categoryId, product, { headers: authHeader() }, );
  }

  deleteProduct(productId) {
    return axios.delete(ENDPOINT_URL + productId, { headers: authHeader() });
  }

  //productIds : [<string>, <string>, ...]
  deleteProducts(productIds) {
    return axios.post(ENDPOINT_URL + 'deletemany', productIds, { headers: authHeader() });
  }

  updateOrder(productIds) {
    return axios.patch(ENDPOINT_URL + 'order', productIds, { headers: authHeader() });
  }

  uploadPhoto(productId, file) {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(ENDPOINT_URL + "uploadPhoto/" + productId, 
                      formData, 
                      { headers: {...authHeader(), 'Content-Type': 'multipart/form-data' }});
  }

}

export default new ProductService();