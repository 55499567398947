import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Box, Button, Layer, Calendar } from 'grommet';
import { Calendar as CalendarIcon } from 'grommet-icons';
import { BookingDates } from './'; 

export default function BookingCalendarInput(props) {

  const { checkinDate, checkoutDate, onChange, disabled } = props;

  const [open, setOpen ] = useState(false);
  const [dates, setDates ] = useState([]);
  const [checkin, setCheckin ] = useState(checkinDate);
  const [checkout, setCheckout ] = useState(checkoutDate);
  
  useEffect(() => {
    setCheckin(checkinDate);
    setCheckout(checkoutDate);
  }, [checkinDate, checkoutDate]);

  const handleOpenCalendar = () =>
  {
    if (disabled) return;
    if (checkinDate && checkoutDate)
      setDates([checkinDate.toISOString(), checkoutDate.toISOString()]);
    else
      setDates([new Date()]);

    setOpen(true);
  }

  const handleCalendarChange = (theDates) =>
  {
    if (theDates && theDates[0] && theDates[0].length === 2)
      setDates(theDates[0]);
  }

  const handleOK = () =>
  {
    if (dates[0])
      setCheckin(dates[0]);
    if (dates[1])
      setCheckout(dates[1]);
    onChange(dates[0], dates[1]);
    closeCalendar();
  }

  const closeCalendar = () =>
  {
    setOpen(false);
  }

  return (
    <Box>
      <Button icon={<CalendarIcon />} 
          label={<BookingDates placeholder="Check-in - Check-out" checkinDate={checkin} checkoutDate={checkout} />} 
          onClick={handleOpenCalendar} style={{"borderRadius": 0, "borderColor": "#888888", "borderWidth":"1px"}} />
      {open && 
        <Layer onEsc={closeCalendar} onClickOutside={closeCalendar}>
          <Box pad="medium" gap="medium">
            <Calendar dates={[dates]} range onSelect={handleCalendarChange}/> 
            <Button label="OK" onClick={handleOK}/>
          </Box>
        </Layer>
      }
    </Box>
  );
};

BookingCalendarInput.propTypes = {};
BookingCalendarInput.defaultProps = {};
