import React, { useState } from 'react';
import { Box, Button, Heading, Layer, Text, Tip, Image } from "grommet";
import { Group, Clock, Download } from "grommet-icons";
import { SecuredImage } from '../common';
import moment from 'moment';
import { Document } from 'react-pdf';
import { GuestService } from "../../services";


export default function VerifyCheckInModal(props) {
  const { onSubmit, status, checkinData, bookingId } = props;
  const [open, setOpen] = useState(false);
  const [zoomedPhoto, setZoomedPhoto] = useState();
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);
  const onCloseZoom = () => setZoomedPhoto(undefined);

  const handleSubmit = (approved) =>
  {
    onClose();
    onSubmit(approved);
  }

  const handleDownloadPdf = () =>
  {
    window.location = GuestService.getPdfURL(bookingId);
    //window.location.href = "/pdf/"+bookingId;
  }

  const renderPhoto = (label, photo) =>
  {
    if (!photo) return;

    const onZoom = (photo) => setZoomedPhoto(photo);

    return  (
              <Box>
                <Text size={18} weight="bold">{label}</Text>
                <Button pad="small" gap="small" background="light-2" round width="200px" onClick={()=>onZoom(photo)}>
                  <SecuredImage className="imageCheckin" filename={photo} style={{borderRadius:10}}/>
                </Button>
              </Box>
          );
  }

  return (
    <div>
      {status === "toverify" ?
              <Button label="Verify Online Check-in" onClick={onOpen} primary color="status-warning" alignSelf="start"/>
              : <Button label="View" onClick={onOpen} primary color="brand" alignSelf="start"/>
      }

      {zoomedPhoto && 
        <Layer position="center" size="large" onClickOutside={onCloseZoom} onEsc={onCloseZoom} onClick={onCloseZoom}>
          <SecuredImage width="100%" filename={zoomedPhoto}/>
        </Layer>
      }

      {open && checkinData && (
        <Layer position="center"  onClickOutside={onClose} onEsc={onClose}>
          <Box pad="medium" gap="small" width="large" height="stretch" alignSelf="start">
            <Heading level={3} margin="none">
              Online Check-in
            </Heading>
            <Box margin={{vertical:"medium"}} direction="row" gap="medium" justify="around">
              <Box>
                <Text  weight="bold">Occupants</Text>
                <Box direction="row" pad="small" gap="small" background="light-2" round width="220px"  height="100px" align="center" margin={{vertical:"small"}}>
                  <Group size="large" style={{marginRight:10}}/>
                  <Box>
                    <Text>Adults: {checkinData.adults}</Text>
                    <Text>Children: {checkinData.children}</Text>
                    <Text>Infants: {checkinData.babies}</Text>
                  </Box>
                </Box>
              </Box>
              <Box >
                <Text  weight="bold">ETA</Text>
                <Box direction="row" pad="small" gap="small" background="light-2" round width="220px" height="100px" align="center" margin={{vertical:"small"}}>
                  <Clock size="large" style={{marginRight:10}}/>
                  <Box>
                    <Text>
                      {checkinData.arrivalTime ? "Your guests will arrive at " + moment().hour(checkinData.arrivalTime).minute(0).format("LT")
                                               : "Your guests didn't enter their arrival time." }
                       </Text>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box margin={{vertical:"medium"}} direction="row" gap="medium" justify="around">
              {renderPhoto("Photo ID", checkinData.photoIDFront)}
              {renderPhoto("Photo ID - Back", checkinData.photoIDBack)}
              {renderPhoto("Selfie", checkinData.photoSelfie)}
            </Box>

            { checkinData.signature &&
              <Box align="center">
                <Button onClick={handleDownloadPdf} primary color="accent-3">
                <Box pad="small" direction="row" align="center" gap="small">
                  <Download />
                  <Text>Download signed registration card</Text>
                </Box>
                </Button>
              </Box>
            }

            {
              status === "toverify" ? 
                <Box direction="row" justify="between">              
                  <Tip dropProps={{ align:{ bottom: 'top', left: 'left' }, background:"status-error"}} 
                       content="Information is missing. The guest will come to the front desk">
                    <Button label="Decline Check-in" onClick={()=>handleSubmit("declined")} primary color="status-error" />
                  </Tip>
                  <Tip dropProps={{ align:{ bottom: 'top', center: 'center' }, background:"status-warning" }}
                       content="Information verified. The key will be sent only after the guest has visited the front desk to complete the check-in.">
                    <Button label="Pre Check-in OK" onClick={()=>handleSubmit("precheck")} primary color="status-warning" />
                  </Tip>
                  <Tip dropProps={{ align:{ bottom: 'top', right: 'right' }, background:"status-ok" }} 
                       content="The guest can skip the front desk. The key will be sent automatically.">
                    <Button label="Approve check-in" onClick={()=>handleSubmit("approved")} primary color="status-ok" />
                  </Tip>
                </Box>
              :
                <Button label="Close" onClick={onClose} color="dark-3" /> 
            } 
          </Box>
        </Layer>
      )}
    </div>
  );
};

VerifyCheckInModal.propTypes = {};
VerifyCheckInModal.defaultProps = {};
