import axios from 'axios';
import { API_URL, authHeader } from './api';

const ENDPOINT_URL = API_URL + 'room-type/';

class RoomTypeService {

  getRoomTypes() {
    return axios.get(ENDPOINT_URL + "list", { headers: authHeader() });
  }

  getRoomType(roomTypeId) {
    return axios.get(ENDPOINT_URL + roomTypeId, { headers: authHeader() });
  }

  addRoomType(roomType) {
    return axios.post(ENDPOINT_URL, roomType, { headers: authHeader() });
  }

  deleteRoomType(roomTypeId) {
    return axios.delete(ENDPOINT_URL + roomTypeId, { headers: authHeader() });
  }

  //roomTypeIds : [<string>, <string>, ...]
  deleteRoomTypes(roomTypeIds) {
    return axios.post(ENDPOINT_URL + 'deletemany', roomTypeIds, { headers: authHeader() });
  }

  updateRoomType(roomType) {
    return axios.patch(ENDPOINT_URL, roomType, { headers: authHeader() } );
  }

  updateOrder(roomTypeIds) {
    return axios.patch(ENDPOINT_URL + 'order', roomTypeIds, { headers: authHeader() });
  }

  uploadPhoto(roomTypeId, file) {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(ENDPOINT_URL + "uploadPhoto/" + roomTypeId, 
                      formData, 
                      { headers: {...authHeader(), 'Content-Type': 'multipart/form-data' }});
  }
}

export default new RoomTypeService();