//export const API_URL = "http://192.168.86.34:4444/v1/";
//export const API_URL = "https://api.loxe.io/v1/";
 export const API_URL = process.env.NODE_ENV === "development" ? 
                                    "http://localhost:4444/v1/" : "https://api.loxe.io/v1/";

export function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'));

  if (user && user.accessToken) {
    // for Node.js Express back-end
    return { 'x-access-token': user.accessToken };
  } else {
    return {};
  }
}