// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import {
  DashboardPage,
  GuestsPage,
  ReservationsPage,
  SettingsHotelInfo,
  SettingsHotelTimes,
  SettingsRoomsAreas,
  SettingsUsers,
  SettingsRoomTypes,
  SettingsUpsell,
  ArchivedPage,
  GuestDetails,
  SettingsPms,
  SettingsCheckin,
  RegistrationCard,
} from './';

export default {
  path: '',
  component: DashboardPage,
  childRoutes: [
    { path: '', component: GuestsPage, isIndex: true },
    { path: 'expected', component: ReservationsPage },
    { path: 'guests', component: GuestsPage },
    { path: 'archived', component: ArchivedPage },
    { path: 'expected/:guestId', component: GuestDetails },
    { path: 'guests/:guestId', component: GuestDetails },
    { path: 'archived/:guestId', component: GuestDetails },
    { path: 'expected/:guestId/:tab', component: GuestDetails },
    { path: 'guests/:guestId/:tab', component: GuestDetails },
    { path: 'archived/:guestId/:tab', component: GuestDetails },
    { path: 'settings', component: SettingsHotelInfo },
    { path: 'settings/info', component: SettingsHotelInfo },
    { path: 'settings/times', component: SettingsHotelTimes },
    { path: 'settings/info', component: SettingsHotelTimes },
    { path: 'settings/doors', component: SettingsRoomsAreas },
    { path: 'settings/users', component: SettingsUsers },
    { path: 'settings/roomtypes', component: SettingsRoomTypes },
    { path: 'settings/upsell', component: SettingsUpsell },
    { path: 'settings/checkin', component: SettingsCheckin },
    { path: 'settings/pms', component: SettingsPms },
    { path: '/pdf/:guestId', component: RegistrationCard }
  ],
};
