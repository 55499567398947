import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Box, Button, Heading, CheckBox} from 'grommet';
import HotelService from "../../services/hotel.service";
import { presentToastSuccess, presentToastErrorContent } from '../common/Toast'
import { useFetchHotel } from './redux/hooks';
import { PriceInput } from '../common'

const defaultValues = {
    deposit:200,
    expressCheckout:true,
    autoSendEnabled:false,
    defaultCheckinOptions:{requestPhotoID:true, 
                           requestCardImprint:true, 
                           offerEarlyCheckin:true,
                           offerUpgrade:true,
                           offerLateCheckout:true
                          }
}

export default function SettingsCheckin(props) {
  const { hotel } = useFetchHotel();
  const [formValues, setFormValues] = useState(defaultValues);

  useEffect(() => {
    if (!hotel)
      setFormValues(defaultValues);
    else
      setFormValues({defaultCheckinOptions: hotel.defaultCheckinOptions || defaultValues.defaultCheckinOptions,
                     deposit: hotel.deposit || defaultValues.deposit, autoSendEnabled: hotel.autoSendEnabled || defaultValues.autoSendEnabled,
                     expressCheckout: hotel.expressCheckout === undefined ? defaultValues.expressCheckout : hotel.expressCheckout
                    });
  }, [hotel]);

  const handleCancel = () =>
  {
    if (!hotel)
      setFormValues(defaultValues);
    else
      setFormValues({defaultCheckinOptions: hotel.defaultCheckinOptions || defaultValues.defaultCheckinOptions,
                     deposit: hotel.deposit || defaultValues.deposit, autoSendEnabled: hotel.autoSendEnabled || defaultValues.autoSendEnabled,
                     expressCheckout: hotel.expressCheckout === undefined ? defaultValues.expressCheckout : hotel.expressCheckout
                    });
  }

  function handleInputChange({target:{name, value}}) {
    var values = {...formValues};
    values[name] = value;
    setFormValues(values);
  }

  function handleCheckboxChange({target:{name, checked}}) {
    var values = {...formValues};
    values.defaultCheckinOptions[name] = checked;
    setFormValues(values);
  }

  function handleCheckboxChange2({target:{name, checked}}) {
    var values = {...formValues};
    values[name] = checked;
    setFormValues(values);
  }

  const handleOnSubmit = (e) =>
  {
    e.preventDefault();
    HotelService.updateHotel(formValues).then(
      (response) => {
        if (response.data.ok)
        {
          presentToastSuccess("Information Updated");
        }
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  return (
    <div>
    <Heading level="1">Online Check-in</Heading>
      
      <Box width="large" gap="medium" background="light-2" round pad="medium">
          <Heading level={3} margin="none"> Default Values </Heading>
          <CheckBox name="autoSendEnabled"
                    checked={formValues.autoSendEnabled}
                    onChange={handleCheckboxChange2}
                    label={"Enable Auto send keys"}/>     
          <CheckBox name="requestPhotoID"
                    checked={formValues.defaultCheckinOptions.requestPhotoID}
                    onChange={handleCheckboxChange}
                    label="Request Photo ID + Selfie"/>
          <CheckBox name="requestCardImprint"
                    checked={formValues.defaultCheckinOptions.requestCardImprint}
                    onChange={handleCheckboxChange}
                    label="Request Card Imprint"/>
          <CheckBox name="expressCheckout"
                    checked={formValues.expressCheckout}
                    onChange={handleCheckboxChange2}
                    label={"Enable Express Checkout"}/>                       
          <CheckBox name="offerEarlyCheckin"
                    checked={formValues.defaultCheckinOptions.offerEarlyCheckin}
                    onChange={handleCheckboxChange}
                    label="Offer Early Check-in"/>
          <CheckBox name="offerUpgrade"
                    checked={formValues.defaultCheckinOptions.offerUpgrade}
                    onChange={handleCheckboxChange}
                    label="Offer Upgrade"/>
          <CheckBox name="offerLateCheckout"
                    checked={formValues.defaultCheckinOptions.offerLateCheckout}
                    onChange={handleCheckboxChange}
                    label={"Offer Late Check-out"}/>                 
          <Box gap="small" width="small">
           <Heading level={4} margin="none">Pre-authorization Deposit</Heading>
            <PriceInput name="deposit" value={formValues.deposit} onChange={handleInputChange} />
          </Box>
      </Box>
      
      <Box direction="row" justify="between" margin={{ top: "medium" }}>
        <Button label="Cancel" onClick={handleCancel}/>
        <Button type="submit" label="Update" primary onClick={handleOnSubmit} />
      </Box>
    </div>
  );
};

SettingsCheckin.propTypes = {};
SettingsCheckin.defaultProps = {};