import axios from 'axios';
import { API_URL, authHeader } from './api';

const ENDPOINT_URL = API_URL + 'hotel/category/';

class CategoryService {

  getAll() {
    return axios.get(ENDPOINT_URL + "all", { headers: authHeader() } );
  }

  getList() {
    return axios.get(ENDPOINT_URL + "list", { headers: authHeader() } );
  }

  getUpsells() {
    return axios.get(ENDPOINT_URL + "upsells", { headers: authHeader() } );
  }

  getCategory(categoryId) {
    return axios.get(ENDPOINT_URL + categoryId, { headers: authHeader() } );
  }

  addCategory(category) {
    return axios.post(ENDPOINT_URL, category, { headers: authHeader() }, );
  }

  updateCategory(category) {
    return axios.patch(ENDPOINT_URL, category, { headers: authHeader() }, );
  }

  deleteCategory(categoryId) {
    return axios.delete(ENDPOINT_URL + categoryId, { headers: authHeader() });
  }

  updateOrder(categoryIds) {
    return axios.patch(ENDPOINT_URL + 'order', categoryIds, { headers: authHeader() });
  }

  uploadPhoto(categoryId, file) {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(ENDPOINT_URL + "uploadPhoto/" + categoryId, 
                      formData, 
                      { headers: {...authHeader(), 'Content-Type': 'multipart/form-data' }});
  }
}

export default new CategoryService();