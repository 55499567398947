import axios from 'axios';
import { API_URL, authHeader } from './api';

const ENDPOINT_URL = API_URL + 'pms/';

class PmsService {
  getList() {
    return axios.get(ENDPOINT_URL + 'list', { headers: authHeader() });
  }

  getBookings() {
    return axios.get(ENDPOINT_URL + 'bookings', { headers: authHeader() });
  }

  importBooking(booking) {
    return axios.post(ENDPOINT_URL + 'import', booking, { headers: authHeader() });
  }

  importDoors() {
    return axios.get(ENDPOINT_URL + 'doors', { headers: authHeader() });
  }

  importRoomTypes() {
    return axios.get(ENDPOINT_URL + 'room-types', { headers: authHeader() });
  }

  helper(api, key) {
    return axios.post(ENDPOINT_URL + 'helper', {api, key}, { headers: authHeader() });
  }

  updateDoorStatus() {
    return axios.get(ENDPOINT_URL + 'doors/status', { headers: authHeader() });
  }
  
}

export default new PmsService();