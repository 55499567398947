import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Text, Box } from 'grommet';

export default function BookingStatus(props) {
  const { status, onClick } = props;

  const [color, setColor] = useState("dark-6");
  

  useEffect(()=>{
    if (status)
    switch (status.slug)
    {
      case "checkedin":
        setColor("neutral-3");
        break;
      case "keysent":
        setColor("status-ok");
        break;
      case "keyused":
        setColor("neutral-1");
        break;
      case "checkedout":
        setColor("neutral-4");
        break;
      case "onlinecheckin":
        setColor("status-warning");
        break;
      case "inperson":
        setColor("brand");
        break;
      case "cancelled":
        setColor("status-error");
        break;
      case "checkinok":
        setColor("neutral-2");
        break;
      case "new":
      default:
        setColor("dark-1");
    }
    

  }, [status]);

  return (
    <Box style={{width: 'fit-content', margin: 'auto'}} pad={{horizontal:'small'}} 
         align="center" round="small" border={{ color:color, size: 'small' }}
         onClick={onClick}>
      <Text size="xsmall" color={color}>{status && status.name}</Text>
    </Box>
  );
};

BookingStatus.propTypes = {};
BookingStatus.defaultProps = {};
