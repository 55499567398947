import React, { useEffect, useState } from 'react';
import { Box, Button, TextInput, Heading, Layer, Text, TextArea, FormField } from "grommet";
import { presentLoading, dismissLoading } from '../common/Loading';
import { presentToastSuccess, presentToastErrorContent } from '../common/Toast';
import RoomTypeService from "../../services/room-type.service";
import PhotoService from "../../services/photo.service";
import { PhotoUploader } from './';
import { PriceInput } from '../common/';

const defaultValues = {
  _id:0,
  name:"",
  description:"",
  upgradePrice:"",
  earlyCheckinPrice:"",
  lateCheckoutPrice:"",
  sleeps:2,
  surface:0
}

export default function AddRoomType(props) {
  const { roomTypeId, onClose } = props;
  const [roomType, setRoomType] = useState(defaultValues);
  const [photo, setPhoto] = useState(undefined);

  function handleInputChange({target:{name, value}}) {
    var values = {...roomType};
    values[name] = value;
    setRoomType(values);
  }

  const handleAdd = () =>
  {
    if (roomTypeId === 0)
    {
      RoomTypeService.addRoomType(roomType).then(
        async (response) => {
            const roomType = response.data && response.data.roomType;
            if (roomType)
              await uploadPhoto(roomType._id);
            onClose(true);
            presentToastSuccess("Success");
        },
        (error) => {
          presentToastErrorContent(error);
        }
      );
    }
    else
    {
      RoomTypeService.updateRoomType(roomType).then(
        async (response) => {
            await uploadPhoto(roomTypeId);
            onClose(true);
            presentToastSuccess("Success");
        },
        (error) => {
          presentToastErrorContent(error);
        }
      );      
    }
  }

  const uploadPhoto = async (typeId) =>
  {
    if (!photo) return;
    await RoomTypeService.uploadPhoto(typeId, photo).then(
      (response) => {
        if (response.data.success)
        {
          //presentToastSuccess("Photo Updated");
        }
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  useEffect(() => {
    if (roomTypeId !== 0)
    {
      presentLoading();
      RoomTypeService.getRoomType(roomTypeId).then(
        (response) => {
          dismissLoading();
          const roomType = response.data;
          if (roomType._id)
          {
            setRoomType(roomType);
          }
        },
        (error) => {
          dismissLoading();
          presentToastErrorContent(error);
        }
      );
    }
    else
    {
      setRoomType(defaultValues);
    }
  }, [roomTypeId]);

  return (
        <Layer position="center" onClickOutside={onClose} onEsc={onClose}>
          <Box pad="medium" gap="small" width="large">
            <Heading level={3} margin="none">
              Add Room Type
            </Heading>
            <Box direction="row"  gap="medium">
              <FormField label="Name" width="medium">
                <TextInput name="name" value={roomType.name} onChange={handleInputChange} />
              </FormField>
              <Box>
                <Text>Photo</Text>
                <PhotoUploader label="Photo (jpg, 980 × 600, <450K)"  fitWidth={980} fitHeight={600} onChange={setPhoto} photo={PhotoService.getURL(roomType.photo) || ""}/>
              </Box>
            </Box>
            <Text>Description</Text>
            <TextArea style={{height:100}} name="description" value={roomType.description} onChange={handleInputChange} />
            <Box direction="row"  gap="medium">
              <FormField label="Surface (sqft)" fill>
                <TextInput value={roomType.surface} name="surface" onChange={handleInputChange} />
              </FormField>
              <FormField label="Number of sleeps" fill>
                <TextInput value={roomType.sleeps} name="sleeps" onChange={handleInputChange} />
              </FormField>
            </Box>
            <Box direction="row"  gap="medium">
              <FormField label="Upgrade price" fill>
                <PriceInput style={{border:0}} value={roomType.upgradePrice} name="upgradePrice" onChange={handleInputChange} />
              </FormField>
              <FormField label="Early Check-in price" fill>
                <PriceInput style={{border:0}} value={roomType.earlyCheckinPrice} name="earlyCheckinPrice" onChange={handleInputChange} />
              </FormField>
              <FormField label="Late Check-out price" fill>
                <PriceInput style={{border:0}} value={roomType.lateCheckoutPrice} name="lateCheckoutPrice" onChange={handleInputChange} />
              </FormField>
            </Box>
            <Box as="footer" gap="small" direction="row" align="center"
              justify="end" pad={{ top: "medium", bottom: "small" }}
            >
              <Button label="Cancel" onClick={onClose} color="dark-3" />
              <Button label={roomTypeId===0?"Add":"Update"} onClick={handleAdd} primary color="status-ok" />
            </Box>
          </Box>
        </Layer>
  );
};

AddRoomType.propTypes = {};
AddRoomType.defaultProps = {};
