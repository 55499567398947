import axios from 'axios';
import { API_URL, authHeader } from './api';

const ENDPOINT_URL = API_URL + 'checkin/';

class CheckinService {

  approve(bookingId, approved) {
    return axios.get(ENDPOINT_URL + bookingId + "/approve/" + approved, { headers: authHeader() }, );
  }

}

export default new CheckinService();