import axios from 'axios';
import { API_URL, authHeader } from './api';

const ENDPOINT_URL = API_URL + 'message/hotel/';

class CommunicationService {
  getMessages(bookingId) {
    return axios.get(ENDPOINT_URL + bookingId, { headers: authHeader() });
  }

  sendMessage(bookingId, message) {
    return axios.post(ENDPOINT_URL + bookingId, {message}, { headers: authHeader() }, );
  }

}

export default new CommunicationService();