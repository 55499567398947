import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Box, Header, DataTable, Button, Heading } from 'grommet';
import { Trash, Lock, Edit, Add } from 'grommet-icons';
import { presentToastSuccess, presentToastErrorContent } from '../common/Toast'
import { AddUser } from './';
import { UserService, AuthService } from "../../services";
import { useSetCurrentUser } from './redux/hooks';
import { presentAlertPrompt } from '../common/Alert';
import { formatPhoneNumber } from 'react-phone-number-input';

export default function SettingsUsers(props) {
  
  const [ list, setList ] = useState([]);
  const { currentUser } = useSetCurrentUser(null);
  const [ openEdit, setOpenEdit ] = useState(false);
  const [ userId, setUserId ] = useState(0);
  
  const columns = [
  {
    property: 'email',
    header: "Email",
    primary: true
  },
  {
    property: 'username',
    header: "Username",
  },
  {
    property: 'role',
    header: "Role"
  },
  {
    property: 'phone',
    header: "Phone",
    render: datum=>formatPhoneNumber(datum.phone)
  },
  {
    property: 'notifications',
    header: "Notifications",
    render: datum=>{
      var arr = [];
      if (datum.notifEmail) arr.push("email");
      if (datum.notifText && datum.phone) arr.push("text");
      return arr.join(', ');
    }
  },
  {
    property: "actions",
    render: datum => (
        <Box direction="row" justify="between" gap="medium">
          <Button icon={<Edit size="small" />} size="small"
                    primary color="status-warning" 
                    onClick={() => handleEdit(datum.id)}
                    />
          { (currentUser.role === 'user' && datum.id !== currentUser.id) ? 
            "" :
            <Button icon={<Lock size="small" />} size="small" label="Reset Pwd" 
                      primary color="neutral-3" 
                      onClick={() => handleResetPassword(datum.id)}
                      />
          }
          
          <Button icon={<Trash size="small" />} size="small" disabled={(datum.id === currentUser.id || currentUser.role === 'user' )}
                    primary color="status-critical" 
                    onClick={() => handleRemoveUser(datum.id)}
                    />
          
        </Box>
       )
  },
  ];

  useEffect(() => {
    onRefresh();
  }, []);

  const onRefresh = () => {
    UserService.getUsers().then(
      (response) => {
        setList(response.data);
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }
  
  const handleEditModalClose = (refresh) =>
  {
    if (refresh)
      onRefresh();

    setOpenEdit(false);
  }
  
  const handleAdd = () => {
    setUserId(0);
    setOpenEdit(true);
  }

  const handleEdit = (userId) => {
    setUserId(userId);
    setOpenEdit(true);
  }

  const handleRemoveUser = (userId) => {
    presentAlertPrompt({title:"Are you sure?", 
                message:"Do you really want to delete this user?", 
                onOK:()=>{removeUser(userId)}, 
                buttonOKText:"Delete",
                buttonOKColor:"status-critical"
    });
  }

  const removeUser = (userId) => {
    UserService.deleteUser(userId).then(
      (response) => {
          onRefresh();
          presentToastSuccess("User successfully removed.");
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  const handleResetPassword = (userId) => {
    presentAlertPrompt({title:"Reset Password?", 
        message:"This will send a new password to this user?", 
        onOK:()=>{resetPassword(userId)}, 
        buttonOKText:"Reset",
        buttonOKColor:"status-critical"
    });
  }

  const resetPassword = (userId) => {
    AuthService.resetPassword(userId).then(
      (response) => {
          onRefresh();
          presentToastSuccess("Password reset.");
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }


  return (
      <Box gap="medium" justify="start">
        <Heading alignSelf="start" level="2">Front-Desk Users</Heading>
        <Header align="center" 
                direction="row" 
                justify="between" 
                gap="medium" 
                fill="horizontal">
          <Button primary icon={<Add size="small" />} size="small" label="Add User" onClick={handleAdd} />
          { openEdit &&
            <AddUser userId={userId} currentUser={currentUser} onClose={handleEditModalClose} />
          }
        </Header>
        <Box align="center" fill="horizontal" >
        <DataTable
          columns={columns}
          data={list}
          primaryKey="id"
        />
        </Box>
      </Box>
  );
};

SettingsUsers.propTypes = {};
SettingsUsers.defaultProps = {};
