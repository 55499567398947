import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Box, Header, Button, Text, Image } from 'grommet';
import { Trash, Edit, Add, Menu } from 'grommet-icons';
import { presentToastSuccess, presentToastErrorContent } from '../common/Toast'
import { AddProduct } from './';
import { roundPrice } from '../../common/utils';
import ProductService from "../../services/product.service";
import { presentAlertPrompt } from '../common/Alert';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import PhotoService from "../../services/photo.service";

export default function ProductsMenu(props) {

  const { hotel, category} = props;
  const [ list, setList ] = useState([]);
  const [ productId, setProductId ] = useState(0);
  const [ openEdit, setOpenEdit ] = useState(false);

  useEffect(() => {
    onRefresh();
  }, [category]);

const DragHandle = sortableHandle(() => <Menu />);

const SortableItem = sortableElement(({value}) => (
  <Box direction="row" gap="medium" justify="between" background={!value.active ? "inactive" : "transparent"}>
    <Box alignContent="center" justify="center" className="sortable-item-column">
      <DragHandle />
    </Box>
    <Box direction="row" gap="medium" width="400px" className="sortable-item-column" align="start">
        <Box height="70px" width="70px">
          <Image height="70px" width="70px"
                  fit="cover"
                  src={PhotoService.getURL(value.photo) || require('../../images/nophoto.jpg')}
                  className="sortable-item-image" onClick={() => handleEdit(value._id)} />
        </Box>
        <Box gap="small" width="330px" className="sortable-item-column">
          <Text weight="bold">{value.name}</Text>
          <Text truncate color="dark-6" style={{fontStyle:'italic'}}>{value.description}</Text>
        </Box>
    </Box>
    <Box direction="row" gap="medium" width="70px" className="sortable-item-column" align="center">
          <Text weight="bold" >${roundPrice(value.price)}</Text>
    </Box>
    {
      category.isMenu && 
        <Box className="sortable-item-column" width="100px" justify="center"> 
          <Text weight="bold" >{value.subCategory}</Text>
        </Box>
    }
    <Box direction="row" gap="medium" className="sortable-item-column">
      <Button icon={<Edit size="small" />} size="small" label="Edit" alignSelf="center"
                primary color="status-warning"
                onClick={() => handleEdit(value._id)}
                />
      <Button icon={<Trash size="small" />} size="small" label="Remove" alignSelf="center"
                primary color="status-critical"
                onClick={() => handleRemoveProduct(value._id)}
                />
    </Box>
  </Box>
));

const SortableContainer = sortableContainer(({children}) => {
  return <Box gap="small" align="start" fill="horizontal">{children}</Box>;
});

const onSortEnd = ({oldIndex, newIndex}) => {
  const newSortedList = arrayMove(list, oldIndex, newIndex);
  const listIds = newSortedList.map(x=>x._id);
  ProductService.updateOrder(listIds).then(
    (response) => {
        setList(newSortedList);
        onRefresh();
    },
    (error) => {
      presentToastErrorContent(error);
    }
  );
};

  const onRefresh = () => {
    ProductService.getProductsInCategory(category._id).then(
      (response) => {
        setList(response.data || []);
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  const handleEditModalClose = (refresh) =>
  {
    if (refresh)
      onRefresh();

    setOpenEdit(false);
  }

  const handleRemoveProduct = (productId) => {
    presentAlertPrompt({title:"Are you sure?",
                message:"Do you really want to delete this product?",
                onOK:()=>{removeProduct(productId)},
                buttonOKText:"Delete",
                buttonOKColor:"status-critical"
    });
  }

  const removeProduct = (productId) => {
    ProductService.deleteProduct(productId).then(
      (response) => {
          onRefresh();
          presentToastSuccess("Product successfully removed.");
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  const handleEdit = (productId) => {
    setProductId(productId);
    setOpenEdit(true);
  }

  const handleAdd = () => {
    setProductId(0);
    setOpenEdit(true);
  }

  return (
      <Box gap="medium" justify="start" margin="small">
        <Header align="center"
                direction="row"
                justify="end"
                gap="medium"
                fill="horizontal">
          <Button primary icon={<Add size="small" />} size="small" label="Add Product" onClick={handleAdd} />
          { openEdit &&
            <AddProduct productId={productId} category={category} onClose={handleEditModalClose} hotel={hotel}/>
          }
        </Header>
        <Box className="products-container">
          <SortableContainer onSortEnd={onSortEnd} useDragHandle>
            {list.map((value, index) => (
              <SortableItem key={`style-${value._id}`} index={index} value={value} />
            ))}
          </SortableContainer>
        </Box>
      </Box>
  );
};

ProductsMenu.propTypes = {};
ProductsMenu.defaultProps = {};
