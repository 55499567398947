export const DASHBOARD_FETCH_HOTEL_BEGIN = 'DASHBOARD_FETCH_HOTEL_BEGIN';
export const DASHBOARD_FETCH_HOTEL_SUCCESS = 'DASHBOARD_FETCH_HOTEL_SUCCESS';
export const DASHBOARD_FETCH_HOTEL_FAILURE = 'DASHBOARD_FETCH_HOTEL_FAILURE';
export const DASHBOARD_FETCH_HOTEL_DISMISS_ERROR = 'DASHBOARD_FETCH_HOTEL_DISMISS_ERROR';
export const DASHBOARD_FETCH_DOORS_BEGIN = 'DASHBOARD_FETCH_DOORS_BEGIN';
export const DASHBOARD_FETCH_DOORS_SUCCESS = 'DASHBOARD_FETCH_DOORS_SUCCESS';
export const DASHBOARD_FETCH_DOORS_FAILURE = 'DASHBOARD_FETCH_DOORS_FAILURE';
export const DASHBOARD_FETCH_DOORS_DISMISS_ERROR = 'DASHBOARD_FETCH_DOORS_DISMISS_ERROR';
export const DASHBOARD_SET_CURRENT_USER = 'DASHBOARD_SET_CURRENT_USER';
export const DASHBOARD_FETCH_ROOM_TYPES_BEGIN = 'DASHBOARD_FETCH_ROOM_TYPES_BEGIN';
export const DASHBOARD_FETCH_ROOM_TYPES_SUCCESS = 'DASHBOARD_FETCH_ROOM_TYPES_SUCCESS';
export const DASHBOARD_FETCH_ROOM_TYPES_FAILURE = 'DASHBOARD_FETCH_ROOM_TYPES_FAILURE';
export const DASHBOARD_FETCH_ROOM_TYPES_DISMISS_ERROR = 'DASHBOARD_FETCH_ROOM_TYPES_DISMISS_ERROR';
