import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { presentToastSuccess, presentToastError, presentToastErrorContent } from '../common/Toast';
import { presentLoading, dismissLoading } from '../common/Loading';
import { presentAlertPrompt } from '../common/Alert';
import { Box, Heading, Button, Text, Meter, Layer } from 'grommet';
import { PmsService } from "../../services";
import { Download } from 'grommet-icons';

export default function ImportBookingsButton(props) {

  const { onComplete } = props;
  
  const [ importProgress, setImportProgress ] = useState({open:false, value:0});
  

  const handleImport = () => {
    presentLoading();
    PmsService.getBookings().then(
      (response) => {
        if (Array.isArray(response.data))
        {
          var updateBookings = (response.data && response.data.reduce((r, x)=>x.update?r+1:r, 0)) || 0;
          var newBookings = (response.data.length || 0) - updateBookings;
          presentAlertPrompt({title:"Bookings import", 
                      message:<Box>
                        <Text>Do you want to import bookings from your PMS?</Text>
                        <Text>New bookings: {newBookings}</Text>
                        <Text>Update bookings: {updateBookings}</Text>
                        </Box>, 
                      onOK:()=>{importBookings(response.data)}, 
                      buttonOKText:"Import",
                      buttonOKColor:"status-warning"
          });
        }
        else if (response.data.imported)
        {
          onComplete();
          presentToastSuccess("Reservations imported");
        }
        else
        {
          presentToastError("Can't import reservations");
        }
        dismissLoading();
      },
      (error) => {
        dismissLoading();
        presentToastErrorContent(error);
      }
    );
  }

  const importBookings = async (bookings) =>
  {
    if (bookings && bookings.length)
    {
      for (var i=0; i < bookings.length; i++)
      {
        await PmsService.importBooking(bookings[i]);
        setImportProgress({open:true, value:100*(i+1)/bookings.length});
      }
    }

    setImportProgress({open:false, value:0});
    onComplete();
    presentToastSuccess("Reservations imported");
  }


  return (
    <div>
      <Button icon={<Download size="small"/>} label="Import from PMS" primary color="status-warning" onClick={handleImport}/>
      {
        importProgress.open && 
        <Layer>
          <Box pad="large">
            <Heading level="2">Importing...</Heading>
            <Meter values={[{ value: importProgress.value }]} />
          </Box>
        </Layer>
      }
    </div>
  );
};

ImportBookingsButton.propTypes = {};
ImportBookingsButton.defaultProps = {};
