import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { Validate } from 'grommet-icons';
import Popover from 'react-popover';

export default function AppInstalled(props) {
  const [ open, setOpen ] = useState(false);

    return (
      <Popover
        key={props.pkey}
        isOpen={open}
        body="The guest has installed the Loxe app"
        children={
          <div key={props.pkey}
            className="appInstalledPopover"
            onMouseOver={() => setOpen(true)}
            onMouseOut={() => setOpen(false)}
          ><Validate size="small" /></div>
        }
      />
    )
}

AppInstalled.propTypes = {};
AppInstalled.defaultProps = {};
