import React, {useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import { Select, TextInput } from 'grommet';
import { timeDataUS, timeData } from '../../common/utils';

export default function HourInput(props) {

  const { name, value, onChange, placeholder, disabled, timezone } = props;
  const [disabledLabel, setDisabledLabel ] = useState("");

  useEffect(()=>{
    if (disabled && value)
    {
      setDisabledLabel(value);
    }
  }, [value, disabled]);

  return (
    !disabled ? 
    <Select
      plain
      name={name}
      placeholder={placeholder || "Select"}
      value={value || "" }
      labelKey="label"
      valueKey={{ key: "value", reduce: true }}
      options={(!timezone || !timezone.startsWith("America")) ? timeData : timeDataUS}
      onChange={({value:nextValue}) => onChange({name, value:nextValue})}
    /> :
    <TextInput plain value={disabledLabel || "" } />
  );
};

HourInput.propTypes = {};
HourInput.defaultProps = {};
