import React from 'react';
// import PropTypes from 'prop-types';
import { MaskedInput, Text } from "grommet";

export default function PriceInput(props) {

  const { value, name, onChange, style } = props;

  return (
    <MaskedInput style={style} icon={<Text weight="bold">$</Text>}
      mask={[
        {
          length: [1, 4],
          regexp: /^[0-9]{1,4}$/,
          placeholder: '0',
        },
        { fixed: '.' },
        {
          length: [1, 2],
          regexp: /^[0-9]{1,2}$/,
          placeholder: '00',
        }
      ]}
      value={value || ""}
      name={name}
      onChange={onChange}
    />
  );
};

PriceInput.propTypes = {};
PriceInput.defaultProps = {};
